import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";

const createslicethunk = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const OrderSlice = createslicethunk({
  name: "SingleAlbumstracks",
  initialState: {
    Songs: [],
  },
  reducers: (create) => ({
    TotalSongs: create.reducer((state, action) => {
      state.Songs = action.payload;
    }),
    CleanSongsArray: create.reducer((state) => {
      state.Songs = [];
    }),
  }),
});

export const { TotalSongs, CleanSongsArray } = OrderSlice.actions;
export default OrderSlice.reducer;
