import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import axios from "axios";
import { promo } from "../../assets";
import { Helmet } from 'react-helmet';

const PromoText = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [howDidYouFindUs, setHowDidYouFindUs] = useState("");
  const [spotifyTrackLink, setSpotifyTrackLink] = useState("");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [amountOfPlays, setAmountOfPlays] = useState("500"); // Default value

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${backendUrl}/freepromotion`, {
        name: name,
        email: email,
        howDidYouFindUs: howDidYouFindUs,
        spotifyTrackLink: spotifyTrackLink,
        amountOfPlays: amountOfPlays,
      });
      console.log(response);
      // Clear form fields after successful submission
      setName("");
      setEmail("");
      setHowDidYouFindUs("");
      setSpotifyTrackLink("");
      setAmountOfPlays("500"); // Reset amount of plays to default value
      // Display toast notification for successful email sending
      toast.success("Email sent");
    } catch (error) {
      console.error("Error sending email:", error);
      // Display toast notification for error
      toast.error("Failed to send email");
    }
  };

  return (
    <>
     <Helmet>
        <title>Get Free Spotify Promotion and Boost Your Music Career </title>
        <meta name="description" content="Elevate your music career with Spotiviral's Free Spotify Promotion and Plays. Our genuine strategies drive real growth for your Spotify profile, connecting you with the right audience and enhancing your visibility on one of the world's top music platforms." />
        <meta name="keywords" content="
Get Free Spotify Promotion, Boost Your Music Career, Genuine Spotify Plays, Organic Spotify Growth, Increase Spotify Visibility, Real Spotify Promotion, Authentic Music Promotion, Spotiviral Free Plays, Free Spotify Streams, Free Music Promotion Service" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="canonical" href="https://spotiviral.com/product/get-free-spotify-Promotion"/>
        <meta name="robots" content="index,follow"/>
        <meta property="og:title" content="Get Free Spotify Promotion and Boost Your Music Career "/>
<meta property="og:description" content="Elevate your music career with Spotiviral's Free Spotify Promotion and Plays. Our genuine strategies drive real growth for your Spotify profile, connecting you with the right audience and enhancing your visibility on one of the world's top music platforms."/>
<meta property="og:image" content="https://spotiviral.com/static/media/SPOTIVIRAL01.3c6258abce199089651d.png"/>
<meta property="og:url" content="https://spotiviral.com/product/get-free-spotify-Promotion"/>
<meta property="og:type" content="website"/>
{/* twitter card */}
<meta name="twitter:title" content="Get Free Spotify Promotion and Boost Your Music Career "/>
<meta name="twitter:description" content="Elevate your music career with Spotiviral's Free Spotify Promotion and Plays. Our genuine strategies drive real growth for your Spotify profile, connecting you with the right audience and enhancing your visibility on one of the world's top music platforms."/>
<meta name="twitter:image" content="https://spotiviral.com/static/media/SPOTIVIRAL01.3c6258abce199089651d.png"/>
<meta name="twitter:card" content="summary_large_image"/>

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        {/* Add more meta tags as needed */}
      </Helmet>
   
    <div>
      <ToastContainer />
      <div className="container mx-auto py-8 px-4 md:px-8 lg:px-32">
        <div className="bg-gray-100 rounded-lg p-5">
          <h1 className="text-green-500 text-3xl font-bold mb-4 text-center">
            FREE SPOTIFY PROMOTION - SPOTIVIRAL
          </h1>
          <p className="text-black mb-8 text-2xl text-center">
            Get Free Spotify Plays
          </p>
          <form onSubmit={handleFormSubmit} className="text-black mb-8 text-lg">
            <div className="mb-4">
              <label className="block mb-1 text-base">Name</label>
              <input
                type="text"
                className="w-full border rounded-md py-1 px-2"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 text-base">Email</label>
              <input
                type="email"
                className="w-full border rounded-md py-1 px-2"
                placeholder="Email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 text-base">How did you find us?</label>
              <input
                type="text"
                className="w-full border rounded-md py-1 px-2"
                placeholder="Google, Facebook.."
                value={howDidYouFindUs}
                onChange={(e) => setHowDidYouFindUs(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 text-base">Spotify Track Link</label>
              <input
                type="text"
                className="w-full border rounded-md py-1 px-2"
                placeholder="Submit only 1 track (Artist profile links or album links will not be accepted)"
                value={spotifyTrackLink}
                onChange={(e) => setSpotifyTrackLink(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 text-base">Select Amount Of Plays</label>
              <select
                className="w-full border rounded-md py-1 px-2"
                value={amountOfPlays}
                onChange={(e) => setAmountOfPlays(e.target.value)}
              >
                <option value="500" className="text-gray-600">500</option>
                {/* Add more options here */}
              </select>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-green-500 text-white py-2 px-4 rounded-md"
              >
                Get Me Plays
              </button>
            </div>
          </form>
        </div>

        <h2 className="text-black text-2xl font-bold mb-4 mt-16 text-center">
          The Steps To Get Free Spotify Promotion:
        </h2>
        <p className="text-gray-600 mb-2 text-base">
          1-Enter Your Info: Insert your email and Spotify music link into our
          easy-to-use form. We ensure to keep your details safe. <a href="https://spotiviral.com/" className="text-blue-500 hover:text-blue-700">Spotiviral </a>
          respects your privacy on top.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          2-After entering all information Select the amount of <a href="https://spotiviral.com/product/spotify-plays" className="text-blue-500 hover:text-blue-700">Spotify plays </a> 
          you would like to get and hit the Get Me Plays Button.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          3-You're done! Your free Spotify plays will be delivered within 2-3
          working days.
        </p>
        <p className="text-green-500 mb-8 text-2xl font-bold text-center">
          Important Note:
        </p>
        <p className="text-gray-600 mb-2 text-base">
          Please note that we don’t accept artist profiles and album links with
          this <a href="https://supremepr.us/free-spotify-promotion-playlist-submissions-tool/" className="text-blue-500 hover:text-blue-700" rel="dofollow">free Spotify promotion service</a>.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          All engagement is genuine: Unlike others, we don’t use bots. Instead,
          we strive for real engagement aiming to attract Spotify users that
          will love your music.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          We have strategies to draw real Spotify plays from real users who will
          love your tunes.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          After processing your submission, watch the progress of your Spotify
          plays.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          We ensure total transparency, letting you see your music’s organic
          growth.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          To avoid spam we have allocated only 10 Spots per day.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4 mt-8 text-center">
          Boost Your Music Career with Spotiviral: Free Spotify Promotion and
          Free Plays on Spotify
        </h2>
        <p className="text-gray-600 mb-8 text-base">
          We know you are a serious musician who wants to expand your Spotify
          audience. This is the best place for you to do it! Spotiviral wants to
          help launch your music career sky high. Our special gift to you: <a href="https://spotiviral.com/product/get-free-spotify-Promotion" className="text-blue-500 hover:text-blue-700">Free
          Spotify Promotion and Plays</a>. Our goal is to help gifted artists with
          500 real Spotify streams using real and organic marketing strategies.
          All you have to do is type in your email and music link in our easy
          form. Then, sit back and watch your music fly.
        </p>
        <div className="flex justify-start mb-8 mt-8">
          <img src={promo} alt="Free Promo" className="w-120 h-96" />
        </div>
        <h2 className="text-black text-2xl font-bold mb-4">
          Why Choose Spotiviral?
        </h2>
        <p className="text-gray-600 mb-8 text-base">
          Here at Spotiviral, we get the hurdles that new artists face in the
          tough music scene. We’ve taken a one-of-a-kind tactic to provide
          organic Spotify streams, to lift your visibility and authentically
          increase your fan base. We use methods that are reliable, assuring the
          plays you get are from real people who like and interact with your
          music.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4 ">
          The Value of Free Spotify Promotion
        </h2>
        <p className="text-gray-600 mb-8 text-base">
          In today’s digital world where music is discovered online, a strong
          online image is critical. Our No-cost Free Spotify Promotion boosts
          your music’s streams and enhances your presence on one of the top
          music platforms worldwide. We use smart, ethical promotional tactics
          to make sure your music connects with the right audience.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4">
          The Upsides of Spotiviral’s Free Spotify Plays:
        </h2>
        <p className="text-gray-600 mb-2 text-base">
          1.True growth: Our real strategies foster authentic and long-term
          growth for your Spotify presence. Forget about meaningless inflated
          numbers with zero impact on your music journey.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          2.Our tactics bring about real, sustainable growth for your Spotify
          profile.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          3.Bid farewell to worthless bloated bot numbers that don’t affect your
          music pursuit.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          4.Better visibility: Growing plays mean better recognition on
          Spotify’s algorithms. This might get you a spot in specially curated
          playlists and exploration by fresh <a href="https://spotiviral.com/product/spotify-monthly-listeners" className="text-blue-500 hover:text-blue-700">listeners</a>. The more plays you get,
          the more Spotify’s algorithms notice you. This attention could put you
          in curated playlists and help you find new listeners.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          5.Gather Your Fans: Find people who love your music and could turn
          into long-lasting supporters.
        </p>
        <p className="text-gray-600 mb-2 text-base">
          6. A strong fanbase can drive a successful music career.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4">
          Free? Why? What’s the Trade-off?
        </h2>
        <p className="text-gray-600 mb-8 text-base">
          Here at Spotiviral, we give back to musicians. Our Free Spotify
          Promotion shows our dedication to new artists. We provide paid help
          for larger promotions, but our free service shows you how good we are
          without a fee.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4">
          Want to Take Your Music Higher? Start Now!
        </h2>
        <p className="text-gray-600 mb-8 text-base">
          Don’t miss this excellent chance to kick off your musical path. Our
          true Spotify plays will not only increase your play count but also
          expose your music to a larger crowd.
        </p>
        <h2 className="text-black text-2xl font-bold mb-4">Lastly:</h2>
        <p className="text-gray-600 mb-8 text-base">
          Spotiviral is more than just a promo company; we’re your success
          partners. Tag along on this musical ride, turning your love for tunes
          into a prosperous career. Feel the effects of genuine Spotify
          promotion – Register for Free Spotify Plays today!
        </p>
      </div>
    </div>
    </>
  );
};

export default PromoText;
