import {
  logoAffordable,
  logoDashboard,
  logoManager,
  logoOrganic,
  logoPerformance,
  logoSupport,
} from "../assets";
import { RiSecurePaymentLine, MdLockOutline, AiOutlineLike } from "../assets";
import { Album, Formcom, Orderslider } from "../components";
import { Orders } from "../components/SingleOrder/Single";
// import { Orders2 } from "../components/SingleOrder/Single";
import { FaArrowPointer } from "react-icons/fa6";
import { FaMusic } from "react-icons/fa6";
import { TbChartHistogram } from "react-icons/tb";
import { Orders2 } from "../components/SingleOrder/Single2";

// Asked Questions
const faqs = [
  {
    question: "What is Spotiviral?",
    answer:
      "SpotViral is your key to unlocking a world of Spotify music promotion and sharing. We’re a dedicated team of digital marketing experts committed to helping artists like you succeed on Spotify. With our tailored campaigns, we turn your melodies into a symphony that resonates with your true audience.",
     
    },
  {
    question: "How can SpotViral benefit me as an independent artist?",
    answer:
      "Great question! We understand the challenges you face as an emerging artist. Spotiviral’s services are designed with you in mind. We target the best audience for your music, driving genuine engagement and turning listeners into dedicated fans. Our strategies are the bridge between your passion and the recognition you deserve.",
  },
  {
    question: " Is this about inflated numbers or real fans?",
    answer:
      "We’re all about real connections here at Spotiviral. We don’t just focus on numbers that mean nothing – we bring you listeners who appreciate your craft and followers who become your music’s advocates. Our campaigns are fueled by targeted ads and social media, ensuring that every fan you gain is an authentic one.",
  },
  {
    question: "How does Spotiviral's campaign work?",
    answer:
      " It’s as simple as creating music itself! Choose a promotion package that resonates with your goals, specify the reach you desire, submit your Spotify track or profile URL, and complete the payment. From there, our experts take over, using strategic campaigns to increase your streams, listeners, saves, and organic followers.",
  },
  {
    question: "When will I see results?",
    answer:
      "We understand your anticipation for the growth of your music. The timelines for our Spotify services vary, providing you with streams, listeners, followers, and playlist placements to boost your music career. With Spotify Streams, you can expect results within 2-3 days after purchase, receiving approximately 400-1000 streams daily. For Spotify Listeners, results typically manifest within 1-2 days after purchase, providing you with 1000-2000 listeners per day. Similarly, with Spotify Followers, you'll notice growth within 1-2 days after purchase, gaining 200-500 followers daily. Lastly, with Spotify Playlist Placement, anticipate results within 3-4 days after purchase, garnering 200-500 streams daily over 30 days.",
  },
  {
    question: "Can I trust Spotiviral's services?",
    answer:
      "Absolutely. Our reputation is built on trust and authenticity. We employ targeted ad campaigns and social media strategies to ensure the fans you gain are real listeners who genuinely connect with your music. We’re here to elevate your music journey, not just your metrics.",
  },
  {
    question: "What makes Spotiviral stand out from other promotion services?",
    answer:
      "We’re not just another service – we’re your partner in music growth. Our genre targeting, tailored campaigns, and focus on authenticity set us apart. We’re not satisfied until your music reaches the hearts of fans who truly resonate with it.",
  },
  {
    question: "How do I get started?",
    answer:
      "Embark on your music’s next chapter by clicking “Start Your Journey.” Join the Spotiviral family and witness the transformation of your music career firsthand. Let’s make your beats resound across the digital airwaves.Still have questions? Reach out to our dedicated support team at support@Spotiviral.com, and we’ll be thrilled to guide you on your music journey.",
  },
];
const playsfaqs = [
  {
    question: "How soon will I see results?",
    answer:
      "Our Spotify Plays Promotion yields noticeable results in just 2-4 days. Your music will experience a surge in plays, giving you the immediate exposure you need.",
      buttonText: "Embark on Your Journey to Musical Stardom Today",
      paragraph: "Your music is more than just soundwaves – it’s a piece of your soul that deserves to be heard. Let Spotiviral turn your melodies into a symphony of success. Your journey to stardom starts now."
    },
  {
    question: "Are these plays from real listeners?",
    answer:
      "Absolutely. Our campaigns are designed to attract real fans who genuinely connect with your music. Say goodbye to inflated numbers and hello to authentic engagement.",
      buttonText: "Embark on Your Journey to Musical Stardom Today",
      paragraph: "Your music is more than just soundwaves – it’s a piece of your soul that deserves to be heard. Let Spotiviral turn your melodies into a symphony of success. Your journey to stardom starts now."
    },
  {
    question: "Can I trust Spotiviral's services?",
    answer:
      "Trust is at the core of our reputation. Our expertise and commitment to authentic growth ensure that your musical journey is in safe hands.",
      buttonText: "Embark on Your Journey to Musical Stardom Today",
      paragraph: "Your music is more than just soundwaves – it’s a piece of your soul that deserves to be heard. Let Spotiviral turn your melodies into a symphony of success. Your journey to stardom starts now."
    },
  {
    question: "What's the impact of premium plays on my music career?",
    answer:
      "Premium plays are the stepping stones to recognition, exposure, and opportunities. With Spotiviral, your music won’t just be heard; it’ll be celebrated.",
      buttonText: "Embark on Your Journey to Musical Stardom Today",
      paragraph: "Your music is more than just soundwaves – it’s a piece of your soul that deserves to be heard. Let Spotiviral turn your melodies into a symphony of success. Your journey to stardom starts now."
    },
  {
    question: "How do I begin my journey with SpotViral?",
    answer:
      "Embark on your music’s transformative journey with a single click. Hit “Elevate My Music” and embrace the rhythm of success.",
      buttonText: "Embark on Your Journey to Musical Stardom Today",
      paragraph: "Your music is more than just soundwaves – it’s a piece of your soul that deserves to be heard. Let Spotiviral turn your melodies into a symphony of success. Your journey to stardom starts now."
    },
  
];
const listenersfaqs = [
  {
    question: "How soon will I see an increase in my Monthly Listeners?",
    answer:
      "Our turnaround is rapid – less than 24 hours. You’ll start witnessing results within 2-3 days after purchase, experiencing a surge in Monthly Listeners that can kickstart your music’s growth.",
      buttonText: "Start Your Journey Today",
      smallText: "Empower Your Music's Global Reach with Spotiviral",
      paragraph: "Your music has the power to transcend boundaries and captivate hearts. Spotiviral’s Spotify Monthly Listeners promotion is your ticket to resonating with a worldwide audience. Step into the spotlight and start your journey today."
    },
  {
    question: "Are these listeners real and engaged?",
    answer:
      "Absolutely. We don’t believe in inflated numbers or empty engagements. Our Spotify Monthly Listeners are genuine, active listeners who appreciate your music.",
      buttonText: "Start Your Journey Today",
      smallText: "Empower Your Music's Global Reach with Spotiviral",
      paragraph: "Your music has the power to transcend boundaries and captivate hearts. Spotiviral’s Spotify Monthly Listeners promotion is your ticket to resonating with a worldwide audience. Step into the spotlight and start your journey today."
    },
  {
    question: "Can I trust Spotiviral's services?",
    answer:
      "Trust is our foundation. Our commitment to authenticity ensures that your musical journey is propelled by real, engaged listeners.",
      buttonText: "Start Your Journey Today",
      smallText: "Empower Your Music's Global Reach with Spotiviral",
      paragraph: "Your music has the power to transcend boundaries and captivate hearts. Spotiviral’s Spotify Monthly Listeners promotion is your ticket to resonating with a worldwide audience. Step into the spotlight and start your journey today."
    },
  {
    question: "How do these Monthly Listeners impact my music career?",
    answer:
      "Monthly Listeners aren’t just numbers; they’re a testament to your music’s popularity. With a higher number of Monthly Listeners, you increase your chances of being discovered, followed, and included in playlists.",
      buttonText: "Start Your Journey Today",
      smallText: "Empower Your Music's Global Reach with Spotiviral",
      paragraph: "Your music has the power to transcend boundaries and captivate hearts. Spotiviral’s Spotify Monthly Listeners promotion is your ticket to resonating with a worldwide audience. Step into the spotlight and start your journey today."
    },
  {
    question: "How do I embark on this journey with Spotiviral?",
    answer:
      "It’s simple – click “Elevate My Music.” Let Spotiviral be your vehicle to musical success.",
   buttonText: "Start Your Journey Today",
      smallText: "Empower Your Music's Global Reach with Spotiviral",
      paragraph: "Your music has the power to transcend boundaries and captivate hearts. Spotiviral’s Spotify Monthly Listeners promotion is your ticket to resonating with a worldwide audience. Step into the spotlight and start your journey today."
    }
];
const playlistfaqs = [
  {
    question: "How does Spotiviral's Playlist Placement work?",
    answer:
      "Our proven process involves placing your track in high-quality playlists through targeted emailing campaigns. This authentic approach guarantees organic streams and genuine engagement.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",
    },
  {
    question: "Will my music reach a global audience?",
    answer:
      "Absolutely. While our playlist network is predominantly based in the USA, the global nature of Spotify ensures that your music will resonate with listeners worldwide.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",

    },
  {
    question: "Are these streams organic?",
    answer:
      "Yes. Our playlist placement is designed to provide authentic streams from engaged listeners who actively seek out tracks similar to yours.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",

    },
  {
    question: "Can I trust Spotiviral's services?",
    answer:
      "Trust is our foundation. With a commitment to transparency, authenticity, and results, SpotViral is your partner in music promotion.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",

    },
  {
    question: "How do I embark on this journey with Spotiviral?",
    answer:
      "Your journey starts with a single click. Hit “Elevate My Music” and let Spotiviral elevate your music to playlist stardom.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",

    },
  {
    question: "How many playlists will my track be added to?",
    answer:
      "For each track, we will research and find the best Spotify playlists for your genre. Each package will have a different number of playlists.In 50k followers Package mostly will be 3 playlists,150k will be 6 playlists, 250k will be 9 playlists and 350 k will be 15 playlists that best fit to your music genre.",
      buttonText: "Start Your Playlist Journey Today",
      smallText: "Position Your Music in the Spotlight with Spotiviral",
      paragraph: "Your music deserves more than just plays; it deserves a stage where it can captivate hearts. Spotiviral’s Spotify Playlist Placement is your ticket to playlists that resonate, listeners who connect, and a musical journey that transcends boundaries.",

    },
];

// Features data
const featuresData = [
  {
    title: "SELECT A PACKAGE",
    description:
      "Go through our promotion packages and select best one that fit to your requirement.",
    logo: <FaArrowPointer color="blue" />,
  },
  {
    title: "SUBMIT YOUR MUSIC",
    description:
      "After selecting the package go through the payment process and submit your track links.",
    logo: <FaMusic color="green" rotate="90deg" />,
  },
  {
    title: "GET RESULTS",
    description:
      "Wait for 24-48 hours and let us create campaign for you to make your songs famous.",
    logo: <TbChartHistogram color="orange" />,
  },
];

// Logos
const logos = {
  affordable: logoAffordable,
  organic: logoOrganic,
  performance: logoPerformance,
  dashboard: logoDashboard,
  manager: logoManager,
  support: logoSupport,
};

// Icons
const icons = [
  {
    icon: <RiSecurePaymentLine />,
    label: "Guaranteed Checkout",
  },
  {
    icon: <MdLockOutline />,
    label: "Secure SSL Encryption",
  },
  {
    icon: <AiOutlineLike />,
    label: "Performance Guaranteed",
  },
];

// MultiOrder Items
const items = (  panelStyle,  pro,  setPro,  pack,  setPackage,  getstate,  setIsactive) => [
  {
    key: 1,
    label: "Your Order",
    children: (
      <Orderslider
        pro={pro}
        setPro={setPro}
        pack={pack}
        setPackage={setPackage}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
  {
    key: 2,
    label: "Select Song",
    children: <Album setIsactive={setIsactive} />,
    style: panelStyle,
  },
  {
    key: 3,
    label: "Confirm Payment",
    children: (
      <Formcom
        pro={pro}
        pack={pack}
        UrlName={getstate}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
];

// Single Order
const singitems = (
  panelStyle,
  pro,
  pack,
  packet,
  statepack,
  setPackage,
  getstate,
  setIsactive
) => [
  {
    key: 1,
    label: "Your Order",
    children: (
      <Orders
        pro={pro}
        pack={pack}
        statepack={statepack}
        setPackage={setPackage}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
  {
    key: 2,
    label: "Select Song",
    children: <Album setIsactive={setIsactive} />,
    style: panelStyle,
  },

  {
    key: 3,
    label: "Confirm Payment",
    children: (
      <Formcom
        pro={pro}
        pack={packet}
        UrlName={getstate}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
];
// singitems2
const singitems2 = (
  panelStyle,
  pro,
  pack,
  packet,
  statepack,
  setPackage,
  getstate,
  setIsactive
) => [
  {
    key: 1,
    label: "Your Order",
    children: (
      <Orders2
        pro={pro}
        pack={pack}
        statepack={statepack}
        setPackage={setPackage}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
 

  {
    key: 2,
    label: "Confirm Payment",
    children: (
      <Formcom
        pro={pro}
        pack={packet}
        UrlName={getstate}
        setIsactive={setIsactive}
      />
    ),
    style: panelStyle,
  },
];

// Packages/Prices
const Plays = [
  { Range: 0, Package: "0 Plays", Price: 0 },
  { Range: 1, Package: "2000 Plays", Price: 8 },
  { Range: 2, Package: "5000 Plays", Price: 15 },
  { Range: 3, Package: "10000 Plays", Price: 25 },
  { Range: 4, Package: "25000 Plays", Price: 60 },
  { Range: 5, Package: "50000 Plays", Price: 110 },
  { Range: 6, Package: "100000 Plays", Price: 180 },
];

const PlayList = [
  { Range: 0, Package: "0 PlayList Placement", Price: 0 },
  { Range: 1, Package: "50k PlayList Placement", Price: 70 },
  { Range: 2, Package: "150k PlayList Placement", Price: 125 },
  { Range: 3, Package: "250k PlayList Placement", Price: 180 },
  { Range: 4, Package: "350k PlayList Placement", Price: 280 },
  { Range: 5, Package: "450k PlayList Placement", Price: 420 },
];

const Listners = [
  { Range: 0, Package: "0 Monthly Listners", Price: 0 },
  { Range: 1, Package: "2000 Monthly Listners", Price: 10 },
  { Range: 2, Package: "5000 Monthly Listners", Price: 20 },
  { Range: 3, Package: "10000 Monthly Listners", Price: 35 },
  { Range: 4, Package: "25000 Monthly Listners", Price: 80 },
  { Range: 5, Package: "50000 Monthly Listners", Price: 120 },
  { Range: 6, Package: "100000 Monthly Listners", Price: 200 },
];

const Follow = [
  { Range: 0, Package: "0 Followers", Price: 0 },
  { Range: 1, Package: "50 Followers", Price: 15 },
  { Range: 2, Package: "100 Followers", Price: 30 },
  { Range: 3, Package: "500 Followers", Price: 130 },
  { Range: 4, Package: "1000 Followers", Price: 250 },
];

// Carosal Data
const carodata = [
  {
    comment:
      "As a band, breaking into new markets was a challenge. Spotiviral's genre targeting and playlist placement gave us the boost we needed. Our music is reaching ears we never thought possible, and the growth has been incredible.",
    Name: "Alex and The W​aves",
    brand: "Indie Band",
    alt: "Spotiviral Feedback"
    //image:
  },
  {
    comment:
      "There’s a reason why Spotiviral is my choice! Fantastic service and results. Totally satisfied with their promotional work. Highly recommended!",
    Name: "MikeRandy",
    brand: "Artist",
  },
  {
    comment:
      "Promotion campaign was very helpful. The results was as described, I am satisfied with the campaign results. I would definitely recommend.",
    Name: "John Mellom",
    brand: "Musician",
  },
  {
    comment:
      "Great promo company Does outstanding work. I would recommend for any artist or label looking to promote their music.",
    Name: "DjFixx",
    brand: "Producer",
  },
  {
    comment:
      "Spotiviral transformed my music career. Within days, I saw a surge in streams and followers. But what truly amazed me was the authentic engagement – my listeners became fans who connected with my music on a whole new level.",
    Name: "Em​ma",
    brand: "Independent Artist",
  },
  {
    comment:
      "As a band, breaking into new markets was a challenge. Spotiviral's genre targeting and playlist placement gave us the boost we needed. Our music is reaching ears we never thought possible, and the growth has been incredible.",
    Name: "Alex and The W​aves",
    brand: "Indie Band",
  },
];

// Exporting
export {
  faqs,
  playsfaqs ,
  featuresData,
  listenersfaqs,
  playlistfaqs,
  logos,
  icons,
  items,
  singitems,
  singitems2,
  Plays,
  Listners,
  Follow,
  carodata,
  PlayList,
};
