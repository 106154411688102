import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { carodata } from "../Constants";
import { spiral , image2,Feed1,Feed2,Feed3,Feed4} from "../assets";

  const Carosol = () => {
    const images = [spiral, Feed4, Feed1, Feed2, Feed3, image2];
  return (
    <div className="w-full md:w-3/5 mx-auto container my-20 px-5">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={`mySwiper `}
        style={{
          maxWidth: "100% ",
          height: "auto",

          borderRadius: "18px",
        }}
      >
        {carodata.map((value, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#000000",
              fontSize: "22px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(255, 255, 255)",
            }}
          >
            <div className="flex flex-col justify-start items-center gap-y-6">
              <h2 className="text-gray-500 text-center text-lg">
                "{value.comment}"
              </h2>
              <div className="flex flex-row justify-center items-center gap-y-5 gap-x-3 ">
              <div className="rounded-full border-2 border-black">
                  <img
                    src={images[index]}
                    alt={value.alt}
                    className="object-contain w-20 h-20 rounded-full"
                    style={{ width: "50px", height: "50px" }} // Set width and height here
                  />
                </div>
                <div className="text-sm">
                  <h4 className="font-bold">{value.Name}</h4>
                  <p>{value.brand}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carosol;
