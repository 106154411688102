import React from "react";

const RefundPolicy = () => {
  return (
    <div className="md:px-20 px-4 mb-10 md:mb-0">
      <h2 className="md:text-5xl text-3xl text-center font-bold py-16  ">
        Refund Policy
      </h2>

      <p>
        At spotiviral.com, our general policy is that once you purchase and
        after we start the promotion the order will not be cancelled and only
        partial refund accepted in those cases.
      </p>
      <p className="mb-6 mt-6">
        {" "}
        However, in a determination to accomplish user satisfaction, if there is
        an issue, you can contact us for any issue through our email{" "}
        <a
          className="text-gray-700 font-bold"
          href="mailto:spotiviral@gmail.com"
        >
          spotiviral@gmail.com
        </a>
        .
      </p>
      <p>
        We will refund you back in the following circumstances:
        <ul className="list-disc ml-8">
          <li>
            If there is duplicate payment made due to technical glitch, or any
            other error;
          </li>
          <li>
            If we make a refund as per the provision of our refund policy which
            is updated by us from time to time;
          </li>
          <li>
            If we consider the refund is necessary as per customer support staff
            as per our sole discretion.
          </li>
        </ul>
      </p>


      <h2 className="text-gray-700 font-bold py-8 text-center text-xl">
        UNETHICAL ORDER & CANCELLATION POLICY
      </h2>

      <p>
        In case any order is made through unethical means; by taking advantage
        of a technical glitch; or by misusing/ the offer terms/guidelines/codes
        – the particular order/s will be canceled whatsoever and{" "}
        <a
          className="text-gray-700 font-bold"
          href="mailto:spotiviral@gmail.com"
        >
          spotiviral@gmail.com
        </a>{" "}
        will not be liable to pay any refund to you in all such cases.
      </p>

      <h2 className="text-gray-700 font-bold py-8 text-center text-xl ">
        METHODS OF PAYMENT AND REFUND
      </h2>

      <p>
        We will refund your amount to the original payment method, once it has
        been processed.
      </p>


      <h2 className="text-gray-700 font-bold py-8 text-center text-xl">
        REFUND CYCLE
      </h2>

      <p>
        The complete refund process normally takes about 3-7 working days from
        the date of confirmation of refund.
      </p>
    </div>
  );
};

export default RefundPolicy;
