import React from "react";

const About = () => {
  return (
    <div className="container mx-auto px-6 md:px-16">
      <div className="bg-white p-4 rounded-lg mb-6 mt-10">
        <p className="text-2xl font-semibold text-center mb-12 mt-36">
          In Simply we are the best Spotify promotion platform in 2023 for many reasons
        </p>

        <div className="flex flex-col md:flex-row items-center justify-between px-4 md:px-24">
          <div className="bg-white w-full md:w-72 h-72 text-center rounded-xl mb-4 md:mb-0">
            <h1 className="mt-12 mb-8 font-semibold text-2xl">Ontime Delivery Rate</h1>
            <p className="text-xl text-gray-400">We have a 96% on-time delivery rate. So we will be punctual in promotions, and we know that is what you are looking for!</p>
          </div>
          <div className="bg-white w-full md:w-96 h-80 rounded-xl text-center shadow-lg mb-4 md:mb-0">
            <h1 className="mt-12 mb-8 font-semibold text-2xl">Targeted marketing</h1>
            <p className="text-xl text-gray-400 text-center px-4">We do genre targeted marketing to get fans who really like the type of your music.</p>
          </div>
          <div className="bg-white w-full md:w-72 h-72 text-center rounded-xl mb-4 md:mb-0">
            <h1 className="mt-12 mb-8 font-semibold text-2xl">Best royalty payouts</h1>
            <p className="text-xl text-gray-400">Specially our listeners campaign got the best royalty payout tested and confirmed by a number of promotions.</p>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-16">
        <h1 className="text-3xl font-bold mb-4">About Us</h1>
        <p className="mb-6">
          <span className="font-bold">Spotiviral</span> is one of the biggest platforms for music artists to get promoted their Spotify songs to a targeted audience through playlists, social media network, and ads campaign.
        </p>
        <h2 className="text-xl font-bold mb-2">Who are we?</h2>
        <p className="mb-6">
          We are the <span className="font-bold">authorized online music promotion company</span> in US which available for thousands of Spotify artists worldwide. Our intentions are to provide <span className="font-bold">organic Spotify campaign</span> for the artists. “<span className="font-bold">spotiviral.com</span>” is an all rights received web service. All the content and services which assigned under <span className="font-bold">Spotiviral trademark</span> which do union under “<span className="font-bold">Spotiviral PVT (Ltd)</span>”. Sign up installation for the site is convenient from every nook and corner on this orb. We accomplish with the <span className="font-bold">premium marketing strategies</span> for your next step. Our tune is accessible in an exclusive movement from October 2020 for every web user. This is the <span className="font-bold">next big icon</span> on your long-term excellence. We pioneer <span className="font-bold">brilliance of musicians</span> to meet the targeted fans.
        </p>
        <p className="mb-6">
          This new web launch for music promotion is the <span className="font-bold">next original homage</span> for your habitual need. We are with our clients to sell on an <span className="font-bold">exact thing</span> what they are looking for. We do equip you the <span className="font-bold">17 most renowned facilities</span> in music. Anyone who is willing our collaboration on their identifiable work will undergo with the <span className="font-bold">uttermost peculiarities</span> on a definition on virtual reality by base. Our web ecommerce go upon with the <span className="font-bold">licit deals</span> on seller and buyer summons. We secure your talents. It’s your chance to explore them to the world and get your exclusive status as an <span className="font-bold">active seller</span>, thus the world can buy your effective deeds they need. We pioneer your <span className="font-bold">brilliance of freelancers</span> to meet the world.
        </p>
      </div>
    </div>
  );
};

export default About;
