import React from "react";
import { useNavigate } from "react-router-dom";
const Error = () => {
  const navigate = useNavigate();
  return (
    <div
      className="w-screen h-screen  bg-gradient-to-br from-teal-600 to-green-300 flex flex-col justify-center items-center text-white gap-8 text-center
    "
    >
      <h1 className="text-4xl">
        <span className="text-6xl">404 -</span> Page Not Found
      </h1>
      <p className="text-xl ">
        <span className="text-xl md:text-3xl">Oops!</span> The page you are
        looking for does not exist.
      </p>
      <button className="p-5 border-none rounded-lg font-semibold bg-rose-500 hover:bg-blue-500"
      onClick={() => navigate('/')}
      >
        Go BackTo HomePage
      </button>
    </div>
  );
};

export default Error;
