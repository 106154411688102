import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSearchData } from "../Store/SearchSlice";
import { useDispatch, useSelector } from "react-redux";

const Search = ({ linkto, pckage }) => {
  const dispatch = useDispatch();
  const { searchData, status, error } = useSelector((state) => state.searched);
  const [search, setSearch] = useState("");
  const [selectedArtist, setSelectedArtist] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedArtist(null);
    if (search.length > 1) {
      const timer = setTimeout(() => {
        dispatch(fetchSearchData(search));
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [search]);

  const handleselect = (artists_data) => {
    window.localStorage.setItem("image", artists_data.images[0].url);
    setSelectedArtist(artists_data);
  };
  const handlePromote = () => {
    const { id, name } = selectedArtist;
    window.localStorage.setItem("artistId", id);
    setSearch("");
    setSelectedArtist("selectedArtist", selectedArtist);
    navigate(linkto, { state: { Name: name, package: pckage } });
  };

  return (
    <div className="relative w-[360px] md:w-[500px] text-black">
      <form className="relative pb-5 md:pb-12">
        <input
          type="search"
          spellCheck={true}
          placeholder="Enter the Spotify Artist name... "
          className="w-full rounded-full p-5 md:p-8 bg-white ring-1 ring-inset ring-black placeholder:text-gray-600"
          value={selectedArtist ? selectedArtist.name : search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div
          className={` ${
            search.length > 0 && !selectedArtist ? "block" : "hidden"
          }  absolute w-full top-24 md:top-24 bg-slate-50  h-64 overflow-y-scroll px-4 md:px-8 py-2 rounded-2xl`}
        >
          {status === "loading" || status === "idle" ? (
            <p className="flex justify-center items-center h-full text-gray-600">
              Loading...
            </p>
          ) : status === "failed" && error ? (
            <p className="flex justify-center items-center h-full text-red-700">
              An Error Occur
            </p>
          ) : (
            searchData?.artists &&
            searchData.artists.items.map((profile, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2"
              >
                <div className="flex items-center">
                  <img
                    src={profile.images[0]?.url}
                    alt={profile.name}
                    className="h-8 md:h-10 w-8 md:w-10 rounded-full mr-2 md:mr-4"
                  />
                  <div>
                    <span className="font-bold text-sm md:text-base">
                      {profile.name}
                    </span>
                    {profile.type === "artist" && (
                      <span className="text-xs md:text-sm px-1">
                        {profile.followers.total} Followers
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="bg-green-500 text-white rounded-full px-3 md:px-4 py-1 md:py-2 hover:bg-green-700 text-xs md:text-sm mt-4"
                    onClick={() => handleselect(profile)}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </form>
      <button
        className=" absolute top-1 md:top-2 right-0 mt-0 md:mt-0 mr-2 px-4 md:px-10 py-5 md:py-6 rounded-full bg-green-500 text-white text-xs md:text-base"
        onClick={() => {
          handlePromote();
        }}
        disabled={selectedArtist ? false : true}
      >
        Start Promotion
      </button>
    </div>
  );
};

export default Search;
