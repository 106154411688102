import React from "react";

function TopToScrollButton({ name }) {
  const GoTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  return (
    <button
      className="py-4 px-10 text-xl rounded-md bg-green-600 text-white hover:bg-red-500 hover:duration-700"
      onClick={GoTo}
    >
      {name}
    </button>
  );
}

export default TopToScrollButton;
