// authSlice.js
import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import axios from "axios";

const createslicethunk = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const authSlice = createslicethunk({
  name: "auth",
  initialState: {
    accessToken: ``,
    status: "idle",
    error: null,
  },
  reducers: (create) => ({
    fetchAccessToken: create.asyncThunk(
      async () => {
        try {
          const response = await axios.post(
            "https://accounts.spotify.com/api/token",
            `grant_type=client_credentials`,
            {
              headers: {
                Authorization: `Basic ${btoa(
                  `${process.env.REACT_APP_ClientId}:${process.env.REACT_APP_ClientSecret}`
                )}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          window.localStorage.setItem(
            "access_token",
            response.data.access_token
          );
          return response.data.access_token;
        } catch (error) {
          throw error.response.data;
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.status = "failed";
        },
        fulfilled: (state, action) => {
          state.status = "succeeded";
          state.accessToken = action.payload;
        },
      }
    ),
  }),
  selectors: {
    gettoken: (state) => state.accessToken,
  },
});

export const { fetchAccessToken } = authSlice.actions;
export const { gettoken } = authSlice.selectors;
export default authSlice.reducer;
