import { FaCheck } from "react-icons/fa";
import { TopToScrollButton } from "./index";
import { Link } from "react-router-dom";

const cardt = [
  {
    Name: "PLAYS",
    Streams: [
      "Real Streams",
      "High quality and Royalties Eligible",
      "100% safe and Better ranking",
      "24/7 Whatsapp and email support",
    ],
    linkto: "/product/spotify-plays",
  },
  {
    Name: "LISTENERS",
    Streams: [
      " Real Monthly Streams",
      "High quality and Royalties Eligible",
      "Increase Listeners organically",
      "24/7 Whatsapp and email support",
    ],
    linkto: "/product/spotify-monthly-listeners",
  },
  {
    Name: "FOLLOWERS",
    Streams: [
      " Guaranteed Followers",
      "Active Followers mostly US",
      "Increase Followers organically",
      "24/7 Whatsapp and email support",
    ],
    linkto: "/product/spotify-followers",
  },
];

const CardCom = ({ name, stream, stream1, stream2, stream3 }) => {
  return (
    <div
      id="card"
      className="container flex justify-center items-center flex-wrap lg:flex-nowrap mx-auto w-screen gap-4 my-20"
    >
      {cardt.map((value, valindex) => (
        <div
          key={valindex}
          className="w-full md:w-[370px] rounded-xl mx-5 md:mx-0 shadow-2xl"
        >
          <div className="flex flex-col md:mx-5  text-center lg:text-left gap-8 my-10">
            <p className="text-sm font-semibold">MOSTLY USA</p>
            <p className="text-center text-xl">PROMOTION</p>
            <h3 className="text-3xl text-green-500 font-bold text-center">
              {value.Name}
            </h3>
            <ol className="space-y-6 text-base font-light text-gray-600">
              {value.Streams.map((strm, index) => (
                <li
                  key={index}
                  className="flex lg:justify-start justify-center gap-x-5 items-center"
                >
                  <span className="text-red-500 text-sm">
                    <FaCheck />
                  </span>
                  {strm}
                </li>
              ))}
            </ol>
            <div className="text-center">
              <Link to={value.linkto}>
                <TopToScrollButton name="Buy Now" />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardCom;
