import React from "react";
import { spiral } from "../assets";
import { FaStar } from "react-icons/fa6";

const val = [
  {
    name: "SATISFACTION",
    perc: "100%",
  },
  {
    name: "DELIVERED ON TIME",
    perc: "96%",
  },
  {
    name: "GUARANTEED RESULTS",
    perc: "100%",
  },
];

export const Rank = ({ perc, name }) => {
  return (
    <div>
      <p>{name}</p>
      <div className="mb-10">
        <div className="bg-gray-600 dark:bg-dark-3 relative h-1.5 w-full rounded-2xl">
          <div
            style={{ width: perc }}
            className={`bg-green-600  absolute top-0  h-full 
             rounded-2xl `}
          >
            <span className="bg-red-400 absolute -right-0 bottom-full mb-2 rounded-lg px-3.5 py-2 text-sm text-white">
              <span className="bg-red-400 absolute bottom-[-2px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm"></span>
              {perc}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = () => {
  return (
    <section className="flex flex-col container justify-center items-center gap-x-5 px-5 my-20 overflow-hidden mx-auto">
      <div className="flex md:flex-row flex-col-reverse justify-between items-center  text-left">
        <div className="md:w-[35%] ">
          <img src={spiral} alt="Spotify promotion" className=" md:h-[400px] h-72" />
        </div>
        <div className="md:w-1/2 flex flex-col justify-center">
          <h2 className="mb-10  text-4xl">
            Over 1000 completed campaigns and counting!
          </h2>
          {val.map((value, index) => (
            <Rank key={index} perc={value.perc} name={value.name} />
          ))}
        </div>
      </div>
      <div className="text-3xl text-center font-semibold text-gray-700 space-y-10">
        <h2>Feedback from Our Raving Fans</h2>
        <i className="flex justify-center items-center  text-yellow-500 gap-x-1">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </i>
      </div>
    </section>
  );
};

export default Testimonial;
