// authSlice.js
import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import axios from "axios";

const createslicethunk = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const Searchslice = createslicethunk({
  name: "auth",
  initialState: {
    searchData: null,
    status: "idle",
    error: null,
  },
  reducers: (create) => ({
    fetchSearchData: create.asyncThunk(
      async (search) => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const response = await axios.get(
            `https://api.spotify.com/v1/search?q=${encodeURIComponent(
              search
            )}&type=artist&limit=50`,
            {
              headers: {
                Authorization: ` Bearer ${accessToken} `,
              },
            }
          );
          return response.data;
        } catch (error) {
          return error.response.data;
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.status = "failed";
        },
        fulfilled: (state, action) => {
          state.status = "succeeded";
          state.searchData = action.payload;
        },
      }
    ),
  }),
});

export const { fetchSearchData } = Searchslice.actions;
export default Searchslice.reducer;
