import { featuresData } from "../Constants";

const Features = () => {
  return (
    <section
      id="features"
      className=" my-20 mx-auto rounded-3xl text-black text-center container"
    >
      <div className="md:w-1/2 mx-auto">
        <h1 className="text-4xl font-semibold mb-8">How to proceed?</h1>
        <h4 className="text-base mb-8 text-gray-600">
  It is easy as click from your finger tips to start an organic   
  <a href="https://www.musicgateway.com/spotify-promotion"  target="_blank" rel="noopener noreferrer"  className="text-blue-500 hover:text-blue-700 mx-1">
      spotify promotion 
  </a>
    campaign on spotiviral
</h4>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-7 ">
        {featuresData.map((feature, index) => (
          <div
            key={index}
            className=" p-6 rounded-lg  flex flex-col items-center justify-center"
          >
            <div className=" mb-4 w-20 h-20 rounded-[2.5rem] hover:rounded-full transition-all hover:duration-[5000ms] ease-in-out  text-7xl flex justify-center items-center bg-green-100 ">
              <span className="rotate-12 py-8 px-7" style={{ fontSize: "3rem" }}> 
              {feature.logo}</span>
            </div>
            <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
            <p className="text-base text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
