import React from 'react';
import TopToScrollButton from './TopToScrollButton';
import { Helmet } from 'react-helmet';
const Spotijourney = () => {

  return (
    <>
     <Helmet>
        <title>Boost Your Music with Spotiviral</title>
        <meta name="description" content="Unlock your music's true potential with Spotiviral tailored Spotify promotion. Connect with genuine fans and amplify your visibility today! " />
        <meta name="keywords" content="Spotiviral, Spotify promotion, Organic Spotify promotion, Spotify followers, Spotify plays, Spotify listeners, Spotify playlists, Spotify growth, Genuine Spotify followers, Spotify marketing" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="canonical" href="https://spotiviral.com/"/>
        <meta name="robots" content="index,follow"/>
        <meta property="og:title" content="Elevate Your Music Journey with Spotiviral Spotify Promotion Services"/>
<meta property="og:description" content="Unlock your music's true potential with Spotiviral tailored Spotify promotion. Connect with genuine fans and amplify your visibility today! "/>
<meta property="og:image" content="https://spotiviral.com/static/media/SPOTIVIRAL01.3c6258abce199089651d.png"/>
<meta property="og:url" content="https://spotiviral.com/"/>
<meta property="og:type" content="website"/>
{/* twitter card */}
<meta name="twitter:title" content="Elevate Your Music Journey with Spotiviral Spotify Promotion Services"/>
<meta name="twitter:description" content="Unlock your music's true potential with Spotiviral tailored Spotify promotion. Connect with genuine fans and amplify your visibility today! "/>
<meta name="twitter:image" content="https://spotiviral.com/static/media/SPOTIVIRAL01.3c6258abce199089651d.png"/>
<meta name="twitter:card" content="summary_large_image"/>

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        {/* Add more meta tags as needed */}
      </Helmet>
   
    <section id="spotijourney" className="bg-white py-12 px-4 md:px-8 lg:px-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl md:text-3xl lg:text-3xl font-bold mb-8">All About Spotiviral</h2>
        <div className="spotijourney-section bg-green text-center animate__animated animate__slideInRight">
          <h3 className="text-2xl md:text-3xl lg:text-3xl text-green-600 font-bold mt-6 mb-6">Amplify Your Music Journey 🚀🚀</h3>
          <p className="text-base md:text-xl lg:text-base text-left text-gray-600">
            Are you ready to take your music to new heights? Spotiviral is here
            to empower emerging artists like you with the tools to <a href="https://savvystar.ai/blog/post/music-on-spotify" className="text-blue-500 hover:text-blue-700" rel="dofollow">skyrocket</a> your
            music journey. We understand the passion that drives your melodies,
            and we’re dedicated to turning your dreams into reality. As a leading
            <a href="https://cdbaby.com/music-promotion/" className="text-blue-500 hover:text-blue-700" rel="dofollow"> music promotion service</a>, we’ve united a team of seasoned digital
            marketing experts who are just as passionate about your music as you
            are. We’ve helped countless artists like you discover their unique
            sound and connect with fans who truly resonate with their melodies.
          </p>
        </div>
        <div className="spotijourney-section bg-green text-center animate__animated animate__slideInRight">
          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-600 font-bold mt-6">Unlock the Power of Your Music:</h2>
        </div>
        <div>
          <h3 className="text-lg md:text-lg text-start lg:text-lg text-black font-bold mt-6">Tailored Campaigns for Your Unique Style:</h3>
          <p className="text-base md:text-lg lg:text-base text-black text-left">
            At <a href="https://spotiviral.com/" className="text-blue-500 hover:text-blue-700">Spotiviral</a>, we don’t believe in one-size-fits-all solutions. Whether your heart beats to
            pop, hip-hop, dance, or another genre, our services are designed to
            embrace your distinctive style and amplify your presence on Spotify.
            Reach the right <a href="https://spotiviral.com/product/spotify-monthly-listeners" className="text-blue-500 hover:text-blue-700">listeners</a> who are hungry for your beats.
          </p>
        </div>
        <div>
          <h3 className="text-lg md:text-lg text-start lg:text-lg text-black font-bold mt-6">Genuine Fans, Authentic Growth</h3>
          <p className="text-base md:text-xl lg:text-base text-gray-600 text-left">
            Forget about inflated numbers that mean nothing. Our campaigns are
            strategically crafted to attract real fans who will engage with your
            music, share it with their circles, and become your lifelong
            supporters. The connections you make here are more than just numbers
            – they’re the heartbeats of your music journey.
          </p>
        </div>
        <div>
          <h3 className="text-lg md:text-lg text-start lg:text-lg text-black font-bold mt-6">Visibility that Commands Attention:</h3>
          <p className="text-base md:text-xl lg:text-base text-gray-600 text-left">
            You’ve poured your soul into your tracks, and they deserve more than
            being lost in the crowd. Let Spotiviral put your music on the map
            with increased streams, listeners, saves, and <a href="https://spotiviral.com/product/spotify-followers" className="text-blue-500 hover:text-blue-700">organic followers</a>. Our
            proven strategies will give your music the spotlight it deserves.
          </p>
        </div>
        <div>
          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-600 text-center font-bold mt-6">Ready to Make Your Spotify Music Resonate?</h2>
          <p className="text-base md:text-xl lg:text-base text-gray-600 mt-6 mb-4 text-left">
            Join Spotiviral and let’s embark on a journey where your beats find
            their true home. Ignite the passion of genuine fans, and watch your
            music transcend boundaries.
          </p>
          <div className="text-center">
            <TopToScrollButton name="Start Your Journey" />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Spotijourney;
