import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlbumTracks } from "../../Store/AlbumTracksSlice";
import { Checkbox, Radio, Select } from "antd";
import { TotalSongs } from "../../Store/orderreduce";

const options = ["Album", "Singles & EPs"];
const Album = ({ setIsactive }) => {
  const dispatch = useDispatch();
  const { Trackss, status } = useSelector((state) => state.albumTracks);

  const [selected, setSelected] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);
  // const { Stracks } = useSelector((state) => state.SingleTracks);
  const { albums } = useSelector((state) => state.artistAlbums);
  const { Singlealbum } = useSelector((state) => state.singlealbums);
  // Managing the state of selected  option
  const onChange = (i) => {
    setSelected((prev) => (i === prev ? null : i));
  };

  // Handeling the Album Selection and dispatching the selected Album id
  const [selID, setSelId] = useState(null);
  const handleOptions = (value) => {
    setSelectedOptions([]);
    setSelId(value);
    dispatch(fetchAlbumTracks(value));
  };

  // Handleing the Selected tracks and saving them
  const hanleTselect = (trackname, trackurl) => {
    if (selectedOptions.some((item) => item.name === trackname)) {
      setSelectedOptions(
        selectedOptions.filter((trac) => trac.name !== trackname)
      );
    } else {
      setSelectedOptions([
        ...selectedOptions,
        { name: trackname, url: trackurl },
      ]);
    }
  };
  const [selsiId, setSelsiId] = useState(null);
  const handleSoption = (value) => {
    setSelectedOptions([]);
    setSelsiId(value);
    dispatch(fetchAlbumTracks(value));
  };

  // handle single
  const hanleTSingleselect = (trackname, trackurl) => {
    setSelectedOptions([{ name: trackname, url: trackurl }]);
  };

  const handlesearch = (value) => {
    console.log("search value", value);
  };

  useEffect(() => {
    setSelectedOptions([]);
    setSelsiId(null);
    setSelId(null);
  }, [selected]);
  useEffect(() => {
    dispatch(TotalSongs(selectedOptions));
  }, [selectedOptions]);

  return (
    <div className="font-medium flex flex-col justify-start items-start gap-5">
      <p className="text-gray-400 text-sm ">
        Your plays are calculated per song
      </p>
      <h1>Select Your Album and Song(s)</h1>
      <div className="flex justify-center items-center gap-x-5">
        {options.map((opt, index) => (
          <label key={index} className="gap-x-4 ">
            <input
              type="radio"
              checked={index === selected}
              onChange={() => onChange(index)}
              className="accent-green-400 border-none"
            />
            {opt}
          </label>
        ))}
      </div>
      <div
        className={`${
          options[selected] === "Album" ? "block" : "hidden"
        } w-full`}
      >
        <Select
          className="w-full h-14 rounded-lg border-gray-300 border"
          defaultValue="Select Album"
          onChange={handleOptions}
          virtual={false}
          showSearch={true}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {albums.map(({ name, id }, index) => (
            <Select.Option key={index} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
        {/* only show when selected option is selected */}
        {selID && (
          <>
            <h3 className="mt-4">Select Songs</h3>
            <ul className="toppings-list max-h-52 mt-4 overflow-y-scroll">
              {status === "loading" ? (
                <p>Loading</p>
              ) : status === "succeeded" ? (
                <Checkbox.Group className="flex flex-col gap-y-3.5 ml-6">
                  {Trackss?.items?.map((track) => (
                    <Checkbox
                      key={track.id}
                      value={track.name}
                      onChange={() =>
                        hanleTselect(track.name, track.external_urls.spotify)
                      }
                    >
                      {track.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              ) : status === "failed" ? (
                <p>Error Occured</p>
              ) : (
                ""
              )}
            </ul>
          </>
        )}
        {/* End Of Multiple Options  List */}
      </div>
      <div
        className={`${
          options[selected] === "Singles & EPs" ? "block" : "hidden"
        } w-full`}
      >
        <Select
          className="w-full h-14 rounded-lg border-gray-300 border"
          defaultValue="Select the Song"
          onChange={handleSoption}
          virtual={false}
          showSearch={true}
          onSearch={handlesearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {Singlealbum.map(({ name, id }, index) => (
            <Select.Option key={index} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
        {selsiId && (
          <>
            <h3 className="mt-4">Select Songs</h3>
            <ul className="toppings-list max-h-52 mt-4 overflow-y-scroll">
              {status === "loading" ? (
                <p>Loading</p>
              ) : status === "succeeded" ? (
                <Radio.Group className="flex flex-col gap-y-3.5 ml-6">
                  {Trackss?.items?.map((track) => (
                    <Radio
                      key={track.id}
                      value={track.name}
                      onChange={() =>
                        hanleTSingleselect(
                          track.name,
                          track.external_urls.spotify
                        )
                      }
                    >
                      {track.name}
                    </Radio>
                  ))}
                </Radio.Group>
              ) : status === "failed" ? (
                <p>Error Occured</p>
              ) : (
                ""
              )}
            </ul>
          </>
        )}
      </div>
      <div className="flex justify-between items-center w-full md:px-3 text-xs md:text-lg">
        <button
          type="button"
          onClick={() => {
            setIsactive(["1"]);
            window.scrollTo(0, 0);
          }}
          className={` mt-11 bg-green-500 py-3 px-7 rounded-lg text-white font-medium hover:bg-green-600`}
        >
          Previous
        </button>
        <button
          type="button"
          className="disabled:bg-red-400 mt-11 bg-green-500 py-3 px-7 rounded-lg  text-white font-medium hover:bg-green-600"
          onClick={() => {
            window.scrollTo(0, 0);
            setIsactive(["3"]);
          }}
          disabled={selectedOptions.length < 1 ? true : false}
        >
          Click Next
        </button>
      </div>
    </div>
  );
};

export default Album;
