// NavbarOrderPayment.jsx
import React from "react";
import { Link } from "react-router-dom";
import { companyLogo } from "../assets";

const NavbarOrderPayment = () => {
  return (
    <nav className="flex justify-center items-center mt-8">
      <Link to="/">
        <img
          src={companyLogo}
          alt="company logo"
          className="scale-125 w-28 md:w-30 lg:w-30 object-contain"
        />
      </Link>
    </nav>
  );
};

export default NavbarOrderPayment;
