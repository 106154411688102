import { Collapse, theme, Slider } from "antd";
import {
  items,
  Plays,
  Listners,
  Follow,
  PlayList,
} from "../../Constants/index";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchArtistAlbums } from "../../Store/ArtistAlbumslice";
import { fetchSingleAlbums } from "../../Store/SingleAlbum";
import { FaPlay } from "react-icons/fa6";
import { CleanSongsArray } from "../../Store/orderreduce";

// Getting Order Packa
export const Orderslider = ({ pro, pack, setIsactive, setPackage }) => {
  const handleplay = (value) => {
    const selected = Plays.find((val) => Number(val.Range) === value);
    selected &&
      setPackage((prev) => {
        return { ...prev, "spotify-plays": selected };
      });
  };
  const handlelisten = (value) => {
    const selected = Listners.find((val) => Number(val.Range) === value);
    selected &&
      setPackage((prev) => {
        return { ...prev, listen: selected };
      });
  };

  const handleplaylist = (value) => {
    const selected = PlayList.find((val) => Number(val.Range) === value);
    selected &&
      setPackage((prev) => {
        return { ...prev, playlist: selected };
      });
  };

  const handlefollow = (value) => {
    const selected = Follow.find((val) => Number(val.Range) === value);
    selected &&
      setPackage((prev) => {
        return { ...prev, follow: selected };
      });
  };

  const handlwactive = () => {
    setIsactive(["2"]);
    window.scrollTo(0, 0);
  };
  return (
    <div className="min-h-80 relative flex flex-col justify-between">
      <div className="flex flex-col justify-center items-center gap-4  md:px-5 mb-1 pt-3 pb-10 border-b-2 border-gray-300">
        <div className="flex justify-between items-center w-full lg:text-xl text-[0.65rem]">
          <h4>{pack["spotify-plays"].Package}</h4>
          <p>Price:({pack["spotify-plays"].Price})$</p>
        </div>
        <div className="relative w-full ">
          <span className="absolute  text-blue w-full left-0 top-5  bottom-[0.68rem] z-0 flex justify-self-start gap-7 ml-2 items-center text-[0.5rem] Anime ">
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
          </span>
          <Slider
            min={0}
            max={Plays.length - 1}
            onChange={handleplay}
            value={pack["spotify-plays"].Range}
            tooltip={{ open: false }}
            styles={{
              track: {
                height: "12px",
                background: "#18be1b",
              },
              handle: {
                position: "absolute",
                top: "3px",
              },
              rail: {
                height: "12px",
                background: "rgba(0, 0, 0, 0.12)",
              },
            }}
          />
        </div>

        <p className="self-start text-gray-400 lg:text-sm text-[0.65rem]">
          Slide Throug to Select Specific Package
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-4  md:px-5 mb-1 pt-3 pb-10 border-b-2 border-gray-300">
        <div className="flex justify-between items-center w-full lg:text-xl text-[0.65rem]">
          <h4>{pack.listen.Package} </h4>
          <p>Price:({pack.listen.Price})$</p>
        </div>
        <Slider
          className="w-full"
          min={0}
          max={Listners.length - 1}
          onChange={handlelisten}
          value={pack.listen.Range}
          tooltip={{ open: false }}
          styles={{
            track: {
              height: "12px",
              background: "#18be1b",
            },
            handle: {
              position: "absolute",
              top: "3px",
            },
            rail: {
              height: "12px",
              background: "rgba(0, 0, 0, 0.12)",
            },
          }}
        />

        <p className="self-start text-gray-400 lg:text-sm text-[0.65rem]">
          Slide Throug to Select Specific Package
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-4  md:px-5 mb-1 pt-3 pb-10 border-b-2 border-gray-300">
        <div className="flex justify-between items-center w-full lg:text-xl text-[0.65rem]">
          <h4>{pack.playlist.Package}</h4>
          <p>Price:({pack.playlist.Price})$</p>
        </div>

        <Slider
          className="w-full"
          min={0}
          max={PlayList.length - 1}
          onChange={handleplaylist}
          value={pack.playlist.Range}
          tooltip={{ open: false }}
          styles={{
            track: {
              height: "12px",
              background: "#18be1b",
            },
            handle: {
              position: "absolute",
              top: "3px",
            },
            rail: {
              height: "12px",
              background: "rgba(0, 0, 0, 0.12)",
            },
          }}
        />
        <p className="self-start text-gray-400 lg:text-sm text-[0.65rem]">
          Slide Throug to Select Specific Package
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-4  md:px-5 mb-1 pt-3 pb-10 border-b-2 border-gray-300">
        <div className="flex justify-between items-center w-full lg:text-xl text-[0.65rem]">
          <h4>{pack.follow.Package} </h4>
          <p>Price:({pack.follow.Price})$</p>
        </div>

        <Slider
          className="w-full"
          min={0}
          max={Follow.length - 1}
          onChange={handlefollow}
          value={pack.follow.Range}
          tooltip={{ open: false }}
          styles={{
            track: {
              height: "12px",
              background: "#18be1b",
            },
            handle: {
              position: "absolute",
              top: "5px",
            },
            rail: {
              height: "12px",
              background: "rgba(0, 0, 0, 0.12)",
            },
          }}
        />

        <p className="self-start text-gray-400 lg:text-sm text-[0.65rem]">
          Slide Throug to Select Specific Package
        </p>
      </div>
      <div className=" flex justify-between items-center px-5 border-b-[1.5px] border-t-2 border-gray-700 py-6 md:w-1/2 md:mx-auto text-xl">
        <h4>Order Total</h4>
        <p>{pro}$</p>
      </div>
      <button
        type="button"
        onClick={handlwactive}
        disabled={
          pack["spotify-plays"].Range < 1 &&
            pack.listen.Range < 1 &&
            pack.follow.Range < 1
            ? true
            : false
        }
        className="disabled:bg-red-400 md:self-end mt-11 bg-green-500 py-3 px-7 rounded-lg text-white font-medium hover:bg-green-600"
      >
        Next: Select Song
      </button>
    </div>
  );
};

// Order Component
function Order() {
  const { albums, status } = useSelector((state) => state.artistAlbums);
  const dispatch = useDispatch();
  const { Songs } = useSelector((state) => state.order);
  const [Isactive, setIsactive] = useState(["1"]);
  const [price, setPrice] = useState(0);
  const [pack, setPackage] = useState({
    "spotify-plays": Plays[1],
    playlist: PlayList[1],
    listen: Listners[1],
    follow: Follow[1],
  });
  useEffect(() => {
    if (Songs.length < 1) {
      const total =
        pack["spotify-plays"].Price +
        pack.listen.Price +
        pack.follow.Price +
        pack.playlist.Price;
      setPrice(total);
    } else {
      const pri = pack["spotify-plays"].Price * Songs.length;
      const priPlaylist = pack["playlist"].Price * Songs.length;
      let total =
        pri + pack.listen.Price + pack.follow.Price + priPlaylist;
      setPrice(total);
    }
  }, [pack, Songs])

  useEffect(() => {
    dispatch(fetchArtistAlbums());
    dispatch(fetchSingleAlbums());
    dispatch(CleanSongsArray());
  }, []);
  
  useEffect(() => {
    GoTo()
  }, []);
  const GoTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.ColorPicker,
    borderRadius: token.borderRadiusLG,
    border: "1px solid black",
    fontWeight: "700",
  };
  return (
    <div className="container min-h-screen mx-auto  max-w-3xl px-5 md:px-10 mt-10">
      <h1 className="md:text-3xl text-3xl text-center font-bold mb-8 bg-gradient-to-r from-green-600 to-green-800 bg-clip-text text-transparent">
        Order Details
      </h1>
      <div className="flex justify-between items-center w-ful h-16 mb-6  bg-white border border-black rounded-xl px-5  font-bold">
        <div className="flex items-center justify-center md:justify-start">
          <div>
            <img
              src={
                status === "loading" ? (
                  <span>Loading</span>
                ) : status === "succeeded" ? (
                  window.localStorage.getItem("image")
                ) : (
                  ""
                )
              }
              alt="Artist"
              className="w-14 h-14 rounded-full"
            />
          </div>
          <div className="flex flex-col items-center md:items-start ml-2">
            <h4 className="">
              {status === "loading" || status === "idle" ? (
                <span>Loading</span>
              ) : (
                albums[0]?.artists[0]?.name
              )}
            </h4>
            <div className="bg-green-500 rounded-full w-auto px-2 text-white mt-2">
              selected
            </div>
          </div>
        </div>

        <p className="text-2xl">{price}$</p>
      </div>
      <Collapse
        items={items(
          panelStyle,
          price,
          setPrice,
          pack,
          setPackage,
          Isactive,
          setIsactive
        )}
        accordion
        activeKey={Isactive}
        defaultActiveKey={["1"]}
        bordered={false}
        size="large"
        expandIconPosition={"end"}
        style={{
          background: token.colorBgContainer,
        }}
      />
    </div>
  );
}

export default Order;
