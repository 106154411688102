import { Link, useLocation, useParams } from "react-router-dom";
import { companyLogo } from "../assets";
import { useEffect, useRef, useState } from "react";
import { TiThMenuOutline } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
const nav = [
  { Nav: "PLAYS", linkTo: "/product/spotify-plays" },
  { Nav: "LISTENERS", linkTo: "/product/spotify-monthly-listeners" },
  { Nav: "PLAYLISTS", linkTo: "/product/spotify-playlists-placement" },
  { Nav: "FOLLOWERS", linkTo: "/product/spotify-followers" },
  { Nav: "FREE PROMO", linkTo: "/product/get-free-spotify-Promotion" },
];

const Navbar = () => {
  const [Active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const getid = useParams();
  const ref = useRef();
  const location = useLocation();
  const pagesWithoutHeaderFooter = [
    "/product/spotify-plays/Payment",
    "/product/spotify-monthly-listeners/Payment",
    "/product/spotify-playlists-placement/Payment",
    "/product/spotify-followers/Payment",
    "/product/get-free-spotify-Promotion",
    "/orderpayment",
    "/terms-and-conditions",
    "/privacy-policy",
    "/refund-policy",
  ]; // Add "/profile" to exclude header and footer for profile.jsx

  // Check if the current page should have header and footer
  const shouldShowHeaderFooter = !pagesWithoutHeaderFooter.includes(
    location.pathname
  );
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <nav
      className={` ${
        !shouldShowHeaderFooter ? "relative" : "absolute"
      } w-screen  shadow-xl md:shadow-none   py-2  z-50 `}
    >
      <div className="relative flex items-center justify-between mx-5   md:px-14 md:mr-16 xl:mr-56">
        {/* Logo */}
        <div className="flex-shrink-0 pt-3 md:block flex justify-end md:justify-start w-2/3 md:w-1/2">
          <Link to="/" onClick={() => setActive("")}>
            <img
              src={companyLogo}
              alt="spotiviral logo"
              className="scale-125  w-28 md:w-30 lg:w-30 object-contain lg:mx-14"
            />
          </Link>
        </div>
        <div className="hidden md:flex justify-center w-1/3 md:w-1/2 items-center text-lg lg:text-sm lg:font-semibold gap-6 lg:gap-10 font-bold">
          {nav.map((value) => (
            <Link
              key={value.Nav}
              to={value.linkTo}
              className={` ${
                Active === value.Nav
                  ? "font-bold "
                  : `   ${
                      !shouldShowHeaderFooter ? "text-black " : "text-white"
                    }`
              } hover:font-bold `}
              onClick={() => setActive(value.Nav)}
            >
              {value.Nav}
            </Link>
          ))}
        </div>
        <div
          className=" md:hidden flex flex-1 justify-end items-end w-full"
          ref={ref}
        >
          <div
            className="text-2xl text-green-700"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? <IoMdClose /> : <TiThMenuOutline />}
          </div>
          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-7 absolute top-14 h-80 right-0 w-full z-20 rounded-md backdrop-blur-3xl  `}
          >
            <div className="flex flex-col justify-center w-full items-center gap-7">
              {nav.map((value) => (
                <Link
                  key={value.Nav}
                  to={value.linkTo}
                  className={` ${
                    Active === value.Nav ? "font-bold " : "text-black font-bold"
                  } hover:font-bold `}
                  onClick={() => setActive(value.Nav)}
                >
                  {value.Nav}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
