import React, { useState } from "react";
import { FaWhatsapp, FaEnvelope, FaTimes } from "react-icons/fa";
import messageIcon from "../assets/images/message.png"; // Import the message icon PNG file

const ContactIcon = () => {
  const [showOptions, setShowOptions] = useState(false);
  const whatsappNumber = "+447360535980";
  const emailAddress = "spotiviral@gmail.com";

  const handleWhatsApp = () => {
    // Open WhatsApp in a new tab
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };

  const handleEmail = () => {
    // Redirect to Email
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 100 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <span
        className="hidden md:inline-block"
          style={{
            backgroundColor: "white",
            padding: "5px 10px",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          Contact Us
        </span>{" "}
        {/* Label */}
        <div
          style={{
            width: 60, // Increased width
            height: 60, // Increased height
            borderRadius: "50%", // Circular shape
            backgroundColor: "#86CD91",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            cursor: "pointer",
            zIndex: 999,
            transition: "transform 0.3s ease-in-out", // Added transition for rotation effect
            transform: showOptions ? "rotate(360deg)" : "rotate(0)", // Rotate icon when options are shown
          }}
          onClick={() => setShowOptions(!showOptions)}
        >
          <img
            src={messageIcon}
            alt="Message"
            style={{ width: 100, height: 100 }}
          />{" "}
          {/* Increased icon size */}
        </div>
      </div>
      {showOptions && (
        <div
          style={{
            position: "absolute",
            bottom: 70,
            right: 8,
            zIndex: 999,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Inside buttons */}
          {/* WhatsApp button */}
          <button
            style={{
              width: 50,
              height: 50,
              borderRadius: "50%",
              backgroundColor: "#25D366",
              color: "white",
              border: "none",
              margin: "5px 0",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 0.3s ease-in-out", // Added transition for hover effect
              transform: "scale(1)", // Initial scale
            }}
            onClick={handleWhatsApp}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")} // Scale up on hover
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")} // Scale back to normal size on mouse leave
          >
            <FaWhatsapp style={{ fontSize: 24 }} />
          </button>
          {/* Email button */}
          <button
            style={{
              width: 50,
              height: 50,
              borderRadius: "50%",
              backgroundColor: "#FFA500",
              color: "white",
              border: "none",
              margin: "5px 0",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 0.3s ease-in-out", // Added transition for hover effect
              transform: "scale(1)", // Initial scale
            }}
            onClick={handleEmail}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")} // Scale up on hover
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")} // Scale back to normal size on mouse leave
          >
            <FaEnvelope style={{ fontSize: 24 }} />
          </button>
          {/* Close button */}
          <button
            style={{
              width: 50,
              height: 50,
              borderRadius: "50%",
              backgroundColor: "#DC3545",
              color: "white",
              border: "none",
              margin: "5px 0",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 0.3s ease-in-out", // Added transition for hover effect
              transform: "scale(1)", // Initial scale
            }}
            onClick={() => setShowOptions(false)}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")} // Scale up on hover
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")} // Scale back to normal size on mouse leave
          >
            <FaTimes style={{ fontSize: 24 }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactIcon;
