import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import { ContactIcon, ScrollTop } from "./components/index";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAccessToken } from "./Store/AuthSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccessToken());
  }, [dispatch]);

  // Add the paths of pages without header and footer
  const pagesWithoutHeaderFooter = ["/home", "/sucess", "/cancel"]; // Add "/profile" to exclude header and footer for profile.jsx

  // Check if the current page should have header and footer
  const shouldShowHeaderFooter = !pagesWithoutHeaderFooter.includes(
    location.pathname
  );

  return (
    <>
      <ScrollTop />
      {shouldShowHeaderFooter && (
        <>
          <Navbar /> <ContactIcon />
        </>
      )}

      <Outlet />
      {shouldShowHeaderFooter && <Footer />}
    </>
  );
}

export default App;
