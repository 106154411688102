import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa"; // Import the tick icon
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
const Success = () => {
  const order = useSelector((state) => state.order.orderData)
  console.log("order", order);
  // let strip_id=localStorage.getItem("strip_id")
  let email = localStorage.getItem("email")
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const strip_id = queryParams.get('strip_id');
  const artist_name = queryParams.get('artist_name');
  const artist_profileLink = queryParams.get('artist_profile_link');

  console.log("strip_id",strip_id);

  // You can now use stripeId as needed, such as sending it to your backend to retrieve additional information
  useEffect(() => {
    const sendMail = async () => {
      const response = await axios.post(`${backendUrl}/sendmail`, {
        strip_id: strip_id,
        msg: "Your order has been placed successfully!",
        data: order,
        artist_name,
        artist_profileLink
      });
      console.log(response);
      localStorage.removeItem("email");
    };
    if (strip_id)
      sendMail();
  }, [])


  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {/* Tick Icon */}
      <div className="text-green-500 text-6xl mb-8">
        <FaCheckCircle />
      </div>

      {/* Confirmation Message */}
      <div className="bg-white rounded-lg p-8 shadow-md text-center">
        <h2 className="text-2xl font-semibold mb-4">Order Confirmation</h2>
        <p className="text-gray-700 mb-4">
          An email has been sent to your email address with order details.
        </p>
        <p className="text-green-500 font-bold mb-4">Payment Successful!</p>
        <Link to="/" className="text-blue-500 underline">
          Go back to homepage
        </Link>
      </div>
    </div>
  );
};

export default Success;