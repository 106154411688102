import { Collapse, theme, Slider } from "antd";
import { singitems } from "../../Constants/index";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleAlbums } from "../../Store/SingleAlbum";
import { fetchArtistAlbums } from "../../Store/ArtistAlbumslice";
import { FaPlay } from "react-icons/fa6";
import { CleanSongsArray } from "../../Store/orderreduce";

export const Orders = ({
  pro,
  setPro,
  pack,
  statepack,
  setPackage,
  setIsactive,
}) => {
  const handlePackage = (value) => {
    const selected = statepack.find((val) => Number(val.Range) === value);
    selected && setPackage(selected);
  };

  return (
    <div className="min-h-80 relative flex flex-col justify-between">
      <div className="flex flex-col justify-center items-center gap-4  md:px-5 mb-1 pt-3 pb-10 border-b-2 border-gray-300">
        <div className="flex justify-between items-center w-full lg:text-xl text-[0.65rem]">
          <h4>{pack.Package}</h4>
          <p>Price:({pack.Price})$</p>
        </div>
        <div className="relative w-full ">
          <span className="absolute  text-blue w-full left-0 top-5 bottom-[0.68rem] z-0 flex justify-self-start gap-7 ml-2 items-center text-[0.5rem] Anime ">
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
            <FaPlay />
          </span>
          <Slider
            min={0}
            max={statepack.length - 1}
            onChange={handlePackage}
            value={pack.Range}
            tooltip={{ open: false }}
            styles={{
              track: {
                height: "12px",
                background: "#18be1b",
              },
              handle: {
                position: "absolute",
                top: "3px",
              },
              rail: {
                height: "12px",
                background: "rgba(0, 0, 0, 0.12)",
              },
            }}
          />
          {/* <input
            type="range"
            min={0}
            max={statepack.length - 1}
            onChangeCapture={handlePackage}
            value={pack.Range}
            step={1}
            onChange={() => handlePackage}
            className="appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-md [&::-webkit-slider-runnable-track]:bg-inherit [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[10px] [&::-webkit-slider-thumb]:z-10 [&::-webkit-slider-thumb]:w-[10px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-purple-500"
            //className="w-full accent-green-500 bg-cyan-800"
          /> */}
        </div>
        <p className="self-start text-gray-400 lg:text-sm text-[0.65rem]">
          Slide Throug to Select Specific Package
        </p>
      </div>

      <div className=" flex justify-between items-center px-5 border-b-[1.5px] border-t-2 border-gray-700 py-6 md:w-1/2 md:mx-auto text-xl">
        <h4>Order Total</h4>
        <p>{pro}$</p>
      </div>

      <button
        type="button"
        onClick={() => {
          setIsactive(["2"]);
          window.scrollTo(0, 0);
        }}
        className={`disabled:bg-red-400 md:self-end mt-11 bg-green-500 py-3 px-7 rounded-lg text-white font-medium hover:bg-green-600`}
        disabled={pack.Range < 1 ? true : false}
      >
        Next: Select Song
      </button>
    </div>
  );
};

const Single = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { albums, status } = useSelector((state) => state.artistAlbums);
  const { Songs } = useSelector((state) => state.order);
  const [Isactive, setIsactive] = useState(["1"]);
  const [pack, setPackage] = useState(state?.package[1]);
  const [price, setPrice] = useState(pack.Price);
  const [packet, setPacket] = useState({});
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.ColorPicker,
    borderRadius: token.borderRadiusLG,
    border: "1px solid black",
    fontWeight: "700",
  };

  useEffect(() => {
    if ((id === "spotify-plays" || id === "playlists-placement") && Songs.length > 0) {
      const total = pack.Price * Songs.length;
      setPrice(total);
      setPacket({ [`${id}`]: pack });
    } else {
      setPrice(pack.Price);
      setPacket({ [`${id}`]: pack });
    }
  }, [pack, Songs]);

  useEffect(() => {
    dispatch(fetchArtistAlbums());
    dispatch(fetchSingleAlbums());
    dispatch(CleanSongsArray())
  }, []);
  useEffect(() => {
    GoTo()
  }, []);
  const GoTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  return (
    <div className="container min-h-screen mx-auto  max-w-3xl px-5 md:px-10 mt-10">
      <h1 className="md:text-3xl text-3xl text-center font-bold mb-8 bg-gradient-to-r from-green-600 to-green-800 bg-clip-text text-transparent">
        Order Details
      </h1>
      <div className="flex justify-between items-center w-ful h-16 mb-6  bg-white border border-black rounded-xl px-5  font-bold">
        <div className="flex items-center justify-center md:justify-start">
          <div>
            <img
              src={
                status === "loading" ? (
                  <span>Loading</span>
                ) : status === "succeeded" ? (
                  window.localStorage.getItem("image")
                ) : (
                  ""
                )
              }
              alt="Artist"
              className="w-14 h-14 rounded-full"
            />
          </div>
          <div className="flex flex-col items-center md:items-start ml-2">
            <h4 className="">
              {status === "loading" || status === "idle" ? (
                <span>Loading</span>
              ) : (
                albums[0]?.artists[0]?.name
              )}
            </h4>
            <div className="bg-green-500 rounded-full w-auto px-2 text-white mt-2">
              selected
            </div>
          </div>
        </div>

        <p className="text-2xl">{price}$</p>
      </div>
      <Collapse
        items={singitems(
          panelStyle,
          price,
          pack,
          packet,
          state.package,
          setPackage,
          state,
          setIsactive
        )}
        accordion
        activeKey={Isactive}
        defaultActiveKey={["1"]}
        bordered={false}
        size="large"
        showArrow={false}
        expandIconPosition={"end"}
        style={{
          background: token.colorBgContainer,
        }}
      />
    </div>
  );
};

export default Single;
