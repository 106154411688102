import { useState } from 'react';
// import { faqs } from "../Constants";
import { FaPlay } from 'react-icons/fa'; // Import the play button icon

const Faq = ( {faqs}) => {
  // console.log("faqs",faqs);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="bg-white py-12 px-4 md:px-24">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">FAQ</h2>
        <div>
          <p className="text-gray-600 mb-8">
            Do you have any questions? Check out our FAQ page or get in touch with our friendly support team today.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
          {faqs.map((faq, index) => (
            <div key={index} className="p-2">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleOpen(index)}>
                <div className="flex items-center"> {/* Play button shape */}
                  <FaPlay className={`${openIndex === index && "rotate-90"} text-green-500 mr-2`} />
                  <h3 className="text-base md:text-lg lg:text-base font-bold justify-start text-left">{faq.question}</h3>
                </div>
              </div>
              {openIndex === index && (
                <>
                <p className="text-gray-600 mt-4 md:mt-6 mb-4 text-start">{faq.answer}</p>
                {/* Button and small text */}
               
              </>
              )}
              {index < faqs.length - 1 && <hr className="border-gray-300" />}
            </div>
          ))}
        </div>
        <hr className="border-gray-300" />
      </div>
    </section>
  );
};

export default Faq;
