import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { icons } from "../../Constants/index";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Form Validation
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is Required"),
  checkbox: yup
    .bool()
    .oneOf([true], "You need to accept the terms and conditions"),
});

// Setting Initial Values
const initialvalue = {
  email: "",
  checkbox: false,
};

// Form Component with Formic and Yup
export const Formcom = ({ pro, pack, UrlName, setIsactive }) => {
  const { Songs } = useSelector((state) => state.order);
  const { albums, status } = useSelector((state) => state.artistAlbums);
  const location = useLocation();
  function convertObjectToOrderDetail(obj) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const orderDetail = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].Range !== "0") {
        console.log("key", key)
        console.log("obj", obj)
        let order_name = "";
        // Customize the order_name based on the key
        switch (key) {
          case "spotify-plays":
            order_name = "SPP";
            break;
          case "playlist":
            order_name = "PP";
            break;
          case "listen":
            order_name = "SPML";
            break;
          case "follow":
            order_name = "SPF";
            break;
          case "spotify-followers":
            order_name = "SPF";
            break;
          case "spotify-monthly-listeners":
            order_name = "SPML";
            break;
          case "playlists-placement":
            order_name = "PP";
            break;
          default:
            order_name = key; // Use the key as order_name by default
            break;
        }
        let packageValue = parseInt(obj[key].Package);
        // Append "K" to every package value
        if (
          location.pathname === "/product/playlists-placement/Payment"
        ) {
          packageValue += "K";
        }
        const order = {
          order_name: order_name,
          order_package: packageValue,
          price: key === "playlist" || key === "spotify-plays" || key === "playlists-placement" ? parseInt(obj[key].Price) * Songs.length : parseInt(obj[key].Price),
        };
        orderDetail.push(order);
      }
    }
    return orderDetail;
  }
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: initialvalue,
    validationSchema: schema,
    onSubmit: async (values) => {
      const order_detail = convertObjectToOrderDetail(pack)
      console.log("orderDetail", order_detail)
      const count = Songs.length;
      const Packagedata = {
        email: values.email,
        order_detail,
        song_name: UrlName.search,
        song_url: UrlName.URL,
        song_details: Songs,
        artist: { name: albums[0]?.artists[0]?.name, profileLink: albums[0]?.artists[0]?.external_urls?.spotify }
      };
      try {
        const queryParams = new URLSearchParams();
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/order`, { order_detail: Packagedata })
        queryParams.append('order_id', res?.data?.id)
        window.location.href = 'https://mindondesigns.netlify.app/?' + queryParams.toString()
        // window.location.href = 'https://stripe-redirection.vercel.app?' + queryParams.toString()
        // window.location.href = 'http://localhost:5174?' + queryParams.toString()

      } catch (error) {
        console.error("Error creating post:", error);
      }
    }
  });
  return (
    <div className="mx-4 mt-2 text-black font-normal">
      <div
        className="flex w-fit gap-x-2 cursor-pointer"
        onClick={() => {
          window.scrollTo(0, 0);
          setIsactive(["1"]);
        }}
      >
        <FaArrowAltCircleLeft className="text-green-400 text-2xl mb-3" />
        <p>Prev</p>
      </div>

      <p className="text-sm text-gray-500">
        Please enter your email address. This is where we will send you updates
        on your campaign
      </p>
      <form action="#" className="flex flex-col " onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          onChange={handleChange("email")}
          value={values.email}
          placeholder="Email"
          onBlur={handleBlur}
          className="p-3 my-4 border-2 border-black rounded-lg font-semibold"
        />
        {touched.email && errors.email && (
          <span className="relative -top-3 left-2 text-red-600 font-semibold md:text-base text-xs">
            {errors.email}
          </span>
        )}
        <label
          htmlFor="check"
          className="relative flex justify-start items-center gap-1 hover:cursor-pointer text-xs md:text-base"
        >
          <input
            type="checkbox"
            name="checkbox"
            id="check"
            onChange={handleChange("checkbox")}
            value={values.checkbox}
            onBlur={handleBlur}
            className="scale-125 mt-[0.150rem] ml-1"
          />
          You agree to our Terms &amp; Refund Policy
        </label>

        {touched.checkbox && errors.checkbox && (
          <span className="relative top-1 text-red-600 font-semibold md:text-base text-xs">
            {errors.checkbox}
          </span>
        )}
        <button
          type="submit"
          className={`disabled:bg-red-400 self-center my-7 bg-green-500 py-3 md:px-7 px-4 rounded-lg text-white font-medium hover:bg-green-600 text-sm`}
          disabled={isSubmitting ? true : false}
        >
          {isSubmitting ? "Processing Order...." : "Continue to Payment"}
        </button>
      </form>
      {/* Bottom Footer of Form */}
      <div className="flex justify-center items-center md:gap-10 gap-2">
        {icons.map((value, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center w-24 mt-7"
          >
            <i className="text-4xl text-gray-400">{value.icon}</i>
            <p className=" text-center text-xs md:text-sm">{value.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
