import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./index.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  Orderpayment,
  PrivacyPolicy,
  Terms,
  RefundPolicy,
  Single,
  Success,
  About,
  Contact,
  Fail,
  Error,
} from "./components";
import Home from "./Pages/Home.jsx";
import SingleOrder from "./Pages/SingleOrder.jsx";
import { store } from "./Store/store.js";
import { Provider } from "react-redux";
import NavbarOrderPayment from "./components/NavbarOrderPayment.jsx";
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route path="" element={<Home />} />
        {/* <Route path="orderpayment" element={<Orderpayment />} /> */}
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="product/:id" element={<SingleOrder />} />
        {/* <Route path="product/:id/Payment" element={<Single />} /> */}
        <Route path="success" element={<Success />} />
        <Route path="cancel" element={<Fail />} />
      </Route>
      <Route path="orderpayment" element={<><NavbarOrderPayment /><Orderpayment /></>} />
        <Route path="product/:id/Payment" element={<><NavbarOrderPayment /><Single /></>} />
        
        

      {/* <Route path="product/:id" element={<><NavbarOrderPayment /><SingleOrder /></>} /> */}


      <Route path="*" element={<Error />} />
    </>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App.js";
// import "./index.css";
// import {
//   Route,
//   RouterProvider,
//   createBrowserRouter,
//   createRoutesFromElements,
//   useParams,
// } from "react-router-dom";
// import {
//   Orderpayment,
//   PrivacyPolicy,
//   Terms,
//   RefundPolicy,
//   Single,
//   Success,
//   About,
//   Contact,
//   Fail,
//   Error,
// } from "./components";
// import Home from "./Pages/Home.jsx";
// import SingleOrder from "./Pages/SingleOrder.jsx";
// import { store } from "./Store/store.js";
// import { Provider } from "react-redux";
// import NavbarOrderPayment from "./components/NavbarOrderPayment.jsx";
// import Single2 from "./components/SingleOrder/Single2.jsx";

// const RouteWithConditionalRender = () => {
//   const { id } = useParams();
//   return (
//     <>
//       {["spotify-monthly-listeners", "spotify-followers"].includes(id) ? (
//         <>
//           <NavbarOrderPayment />
//           <Single2 />
//         </>
//       ) : (
//         <>
//           <NavbarOrderPayment />
//           <Single />
//         </>
//       )}
//     </>
//   );
// };

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <>
//       <Route path="/" element={<App />}>
//         <Route path="" element={<Home />} />
//         <Route path="privacy-policy" element={<PrivacyPolicy />} />
//         <Route path="terms-and-conditions" element={<Terms />} />
//         <Route path="about" element={<About />} />
//         <Route path="contact" element={<Contact />} />
//         <Route path="refund-policy" element={<RefundPolicy />} />
//         <Route path="product/:id" element={<SingleOrder />} />
//         <Route path="success" element={<Success />} />
//         <Route path="cancel" element={<Fail />} />
//       </Route>
//       <Route path="orderpayment" element={<><NavbarOrderPayment /><Orderpayment /></>} />
//       <Route path="product/:id/Payment" element={<RouteWithConditionalRender />} />
//       <Route path="*" element={<Error />} />
//     </>
//   )
// );

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <Provider store={store}>
//     <RouterProvider router={router} />
//   </Provider>
// );
