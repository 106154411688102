import Search from "./Search";
import Typewriter from "typewriter-effect";
import { homepage } from "../assets";
import SPOTIVIRAL01 from "../assets/images/SPOTIVIRAL01.png"
import SPOTIVIRAL02 from "../assets/images/SPOTIVIRAL02.png";
import Imgix from 'react-imgix';
const Hero = ({ pic = homepage, linkto = "/orderpayment", pckage = "" }) => {
  return (
    <div className=" md:bg-hero-pattern bg-none bg-no-repeat bg-right-top  bg-contain">
      <section
        id="hero"
        className="container w-screen bg-green-500 md:bg-transparent min-h-screen relative flex flex-col justify-center lg:flex-row gap-20 items-center mb-10 mx-auto pt-40 md:pt-52 "
      >
        {/* Content */}
        <div className="flex flex-col justify-center items-center space-y-12 lg:w-1/2 mx-auto lg:ml-36">
          <div className="max-w-3xl text-4xl font-bold text-center lg:text-5xl flex flex-col gap-x-5 gap-y-2 ">
            <p className="">We Help You Gain</p>
            <div className="flex justify-center items-center">
              <p>More </p>
              <div className="lg:bg-gradient-to-r from-green-600 to-green-900 bg-white bg-clip-text text-transparent ml-4">
                <Typewriter
                  options={{
                    strings: ["Plays", "Listeners", "Followers"],
                    autoStart: true,
                    loop: true,
                    pausefor: 4000,
                  }}
                />
              </div>
            </div>
          </div>

          <Search linkto={linkto} pckage={pckage} />
          
  {/* Show image on large screens */}
          <img src={SPOTIVIRAL02} alt="spotify promotion campaign" 
          className="hidden lg:block w-48 m-auto" 
          style={{ marginTop: '-70px' }}
          imgixParams={{ auto: 'format', fit: 'crop' }}
          loading="lazy"
          />


  {/* Show image on mobile screens */}
  <img src={SPOTIVIRAL01} alt="spotify promotion campaign" className="block lg:hidden w-[330px] h-[330px]"
  imgixParams={{ auto: 'format', fit: 'crop' }}
  loading="lazy"
  width={150} 
  height={150}
  />
        </div>
        {/* Sound Illustration Image */}
        <div className="hidden lg:flex justify-start lg:w-1/2 lg:mr-12  ">
          <img src={pic} className="bg-blend-multiply " alt="SpotViral" 
          imgixParams={{ auto: 'format', fit: 'crop' }}
          loading="lazy"
          />
        </div>
      </section>
    </div>
  );
};

export default Hero;
