import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import axios from "axios";

const createslicethunk = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const AlbumTracksSlice = createslicethunk({
  name: "Albumstracks",
  initialState: {
    Trackss: null,
    status: "idle",
    error: null,
  },
  reducers: (create) => ({
    fetchAlbumTracks: create.asyncThunk(
      async (albumId) => {
        try {
          const accessToken = localStorage.getItem("access_token");

          const response = await axios.get(
            ` https://api.spotify.com/v1/albums/${albumId}`,
            {
              headers: {
                Authorization: ` Bearer ${accessToken} `,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          return response.data.tracks;
        } catch (error) {
          return error.response.data;
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.status = "failed";
        },
        fulfilled: (state, action) => {
          state.status = "succeeded";
          state.Trackss = action.payload;
        },
      }
    ),
  }),
});

export default AlbumTracksSlice.reducer;
export const { fetchAlbumTracks } = AlbumTracksSlice.actions;
