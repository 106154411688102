import React from "react";
import { image1 } from "../assets";
import TopToScrollButton from "./TopToScrollButton";
const Page = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col justify-evenly items-center  md:gap-x-20 gap-y-4  my-10  container mx-auto lg:px-0 px-4">
        <div className="w-full md:w-2/3 lg:w-1/3">
          <img
            src={image1}
            alt="Spotify music promotion service"
            className="w-full h-auto object-contain"
          />
        </div>
        <div className="w-full lg:w-1/2 text-left">
          <h2 className="md:text-4xl text-2xl mb-5 font-medium leading-10">
            Get a jump Start on your music career!
          </h2>
          <p className="leading-loose mb-5 text-base text-justify text-gray-600">
            SpotViral is one of the leading <a href="https://en.wikipedia.org/wiki/Spotify" className="text-blue-700 hover:text-blue-800">Spotify music</a> promotion service
            providers and offers Spotify promotion campaigns with ease and
            efficiency. Through Spotiviral artist can grow their streams and
            regular fanbase and lead a successful career on their own music.
          </p>
          <div className="flex justify-center items-center ">
            <TopToScrollButton name="Try it Now" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
