import { terms } from "../Constants/terms";

const Terms = () => {
  return (
    <div className="lg:mx-24 mx-5 text-justify">
      <h1 className="md:text-3xl text-balance text-center font-bold py-5 md:py-6">
        TERMS AND CONDITIONS
      </h1>
      <h1 className="md:text-3xl text-balance text-center font-bold md:py-6">
        This Agreement was last revised on January 14th, 2021.
      </h1>
      {terms.map((term, index) => (
        <div key={index}>
          {/* <h2 className='text-2xl text-center font-bold py-4'>Overview</h2> */}
          <h2 className="text-center md:text-2xl text-xl font-bold text-black mt-12 mb-8">
            {term.head}
          </h2>
          {term.text.map((tex, index) => (
            <p key={index}>{tex}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Terms;
