import { image2 } from "../assets";

import { logoAffordable, logoOrganic, logoPerformance } from "../assets";
const data = [
  {
    title: "Genre Targeting",
    description:
      "If you do hip-hop, pop ,dance or blue doesn’t matter we accept almost all genre of spotify music.",
    logo: logoAffordable,
  },
  {
    title: " Spotify Growth Boosting",
    description:
      "Our campaigns will  boost your plays, Spotify listeners,saves and organic followers in consistence manner.",
    logo: logoOrganic,
  },
  {
    title: "Results Monitoring",
    description:
      "You can monitor all our campaign results through your Spotify artist dashboard",
    logo: logoPerformance,
  },
];
const CallToAction = () => {
  return (
    <section
      id="cta"
      className="flex lg:flex-row md:flex-col-reverse flex-col justify-evenly items-center px-10 md:gap-10 container mx-auto mt-10"
    >
      <div className="lg:w-[35%] w-full flex flex-col justify-center items-start gap-10 text-center md:text-left">
        <h2 className="text-bold md:text-[2.3rem] text-2xl  leading-tight">
          Get started today with our Organic Spotify promotion campaigns
        </h2>
        <p className="text-gray-500 font-base">
          Our organic Spotify promotion campaigns are fit for any genre of music
          as we target the best audience for your music to get more engagements.
        </p>

        {data.map((value) => (
          <div
            key={value.title}
            className="flex flex-col md:flex-row justify-start items-center md:items-start gap-x-5 mx-auto"
          >
            <img src={value.logo} alt="logo" />
            <div className="flex flex-col justify-center md:items-start items-center gap-y-4">
              <h3 className="text-2xl font-semibold">{value.title}</h3>
              <p className="text-gray-600 text-base">{value.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center md:w-1/3  ">
        <img src={image2} alt="Organic Spotify promotion campaigns" className="h-[600px] object-contain" />
      </div>
    </section>
  );
};

export default CallToAction;
