import { configureStore } from "@reduxjs/toolkit";
import Totalsongs from "./orderreduce"; // Use OrderReducer instead of OrderSlice
import AlbumReducer from "./AlbumTracksSlice";
import SearchReducer from "./SearchSlice";
import ArtistReducer from "./ArtistAlbumslice";
import SingleAlbum from "./SingleAlbum";
import AuthReducer from "./AuthSlice";

export const store = configureStore({
  reducer: {
    order: Totalsongs, // Use OrderReducer instead of OrderSlice
    authenti: AuthReducer,
    searched: SearchReducer,
    artistAlbums: ArtistReducer,
    singlealbums: SingleAlbum,
    albumTracks: AlbumReducer,
  },
});
