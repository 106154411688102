import {
  Hero,
  Features,
  Testimonial,
  Faq,
  CallToAction,
  Carosol,
  Spotijourney,
  CardCom,
  Page,
  ContactIcon,
} from "../components/index";
import { faqs } from "../Constants";
function Home() {
  return (
    <>
      <Hero />
      <ContactIcon />
      <Features />
      <Page />
      <CardCom />
      <CallToAction />
      <Testimonial />
      <Carosol />
      <Spotijourney />
      <Faq faqs={faqs} /> 
      {/* <Faq  /> */}
    </>
  );
}

export default Home;
