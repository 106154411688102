import {
  Hero,
  Playtext,
  ListenerText,
  PlaylistText,
  FollowerText,
  PromoText,
} from "../components/index";
import { play, listener, follower, playlist } from "../assets";
import { Plays, Listners, Follow, PlayList } from "../Constants";
import { useParams } from "react-router-dom";
const SingleOrder = () => {
  const getid = useParams();
  return (
    <div>
      {getid.id === "spotify-plays" ? (
        <>
          <Hero
            linkto="/product/spotify-plays/Payment"
            pckage={Plays}
            pic={play}
          />
          <Playtext />
        </>
      ) : getid.id === "spotify-playlists-placement" ? (
        <>
          <Hero
            linkto="/product/playlists-placement/Payment"
            pckage={PlayList}
            pic={playlist}
          />
          <PlaylistText />
        </>
      ) : getid.id === "spotify-monthly-listeners" ? (
        <>
          <Hero
            linkto="/product/spotify-monthly-listeners/Payment"
            pckage={Listners}
            pic={listener}
          />
          <ListenerText />
        </>
      ) : getid.id === "spotify-followers" ? (
        <>
          <Hero
            linkto="/product/spotify-followers/Payment"
            pckage={Follow}
            pic={follower}
          />
          <FollowerText />
        </>
      ) : getid.id === "get-free-spotify-Promotion" ? (
        <>
          <PromoText />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleOrder;
