import React from "react";
import { Link } from "react-router-dom";
import { FaTimesCircle } from "react-icons/fa"; // Import the cross icon

const Fail = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {/* Cross Icon */}
      <div className="text-red-500 text-6xl mb-8">
        <FaTimesCircle />
      </div>

      {/* Message */}
      <div className="bg-white rounded-lg p-8 shadow-md text-center">
        <h2 className="text-2xl font-semibold mb-4">Payment Failed</h2>
        <p className="text-gray-700 mb-4">
          Oops! Something went wrong with your payment. Please try again.
        </p>
        <Link to="/" className="text-blue-500 underline">
          Go back to homepage
        </Link>
      </div>
    </div>
  );
};

export default Fail;
