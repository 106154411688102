import { Link } from "react-router-dom";
import { companyLogo, Footerback } from "../assets"; // Assuming you have a `footerBackground` image in your assets
import { facebook, instagram, twitter,  strip } from "../assets";
const Footer = () => {
  return (
    <footer
      className="relative flex flex-col justify-end items-center"
      style={{
        minHeight: "calc(100vh - 4rem)", // Adjust the 4rem to your header/footer height
        backgroundImage: `url(${Footerback})`,
        backgroundSize: "cover",
        backgroundPosition: "left",
      }}
    >
      <div className="container flex flex-col md:flex-row mx-auto items-center justify-around w-full px-3">
        {/* Company Logo */}
        <div className="mb-8 md:mb-0">
          <img
            src={companyLogo}
            alt="spotiviral Logo"
            style={{ maxWidth: "150px" }}
          />
        </div>
        {/* About */}
        <div className="flex flex-col items-center md:items-start px-8">
          <h3 className="text-xl font-bold mb-4">About</h3>
          <ul>
            <li>
              <Link to="/terms-and-conditions" className="hover:text-green-500">
                Term of Service
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:text-green-500">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/refund-policy" className="hover:text-green-500">
                Refund Policy
              </Link>
            </li>
          </ul>
        </div>
        {/* Categories */}
        <div className="flex flex-col items-center md:items-start px-8 mt-2">
          <h3 className="text-xl font-bold mb-4">Categories</h3>
          <ul>
            <li>
              <Link to="/product/spotify-plays" className="hover:text-green-500">
                Plays
              </Link>
            </li>
            <li>
              <Link to="/product/spotify-monthly-listeners" className="hover:text-green-500">
                Listeners
              </Link>
            </li>
            <li>
              <Link to="/product/spotify-playlists-placement" className="hover:text-green-500">
                PlayLists
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-center md:items-start px-8 mb-2 mt-2">
          <h3 className="text-xl font-bold mb-4">Support</h3>
          <ul>
            <li>
              <Link to="/contact" className="hover:text-green-500">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:text-green-500">
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex-col items-center justify-center">
          {/* Social media icons */}
          {/* Social media icons */}
          <div className="flex items-center justify-center mt-8 md:mt-0 gap-x-3 mr-2">
            <Link
              to="https://web.facebook.com/Teamdmpp/?_rdc=1&_rdr"
              target={"_blank"}
            >
              <img
                src={facebook}
                alt="Facebook"
                className="w-8 h-8 md:w-8 md:h-8 "
              />
            </Link>
            <Link to="https://twitter.com/spotiviral" target={"_blank"}>
              <img
                src={twitter}
                alt="Twitter"
                className="w-8 h-8 md:w-8 md:h-8 "
              />
            </Link>
            <Link to="https://www.instagram.com/spotiviral/" target={"_blank"}>
              <img
                src={instagram}
                alt="Instagram"
                className="w-8 h-8 md:w-8 md:h-8 "
              />
            </Link>
          </div>
          {/* Cards icons */}
          <div className="flex items-center justify-center  md:mt-0 px-8">
            {/* <img
              src={credit}
              alt="Credit Card"
              className="w-12 h-12 md:w-12 md:h-12 mr-4 "
            /> */}
            <img
              src={strip}
              alt="Stripe"
              className="w-36 h-12 md:w-38 md:h-12 mr-1"
            />
            {/* <img
              src={debit}
              alt="Debit Card"
              className="w-12 h-12 md:w-12 md:h-12 mr-4"
            /> */}
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="text-center mt-4 mb-4">
        <p>&copy; 2024, Spotiviral. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
