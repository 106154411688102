// Privacypolicy.jsx

import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="py-5 md:px-14 xl:px-20 text-justify px-5">
      <h1 className="text-4xl font-bold text-center mt-6 mb-10">
        Privacy Policy
      </h1>
      <p className="text-md font-bold mt-6 mb-4">
        Last updated: January 14th, 2021
      </p>


      <p>
        Our Privacy Policy forms part of and must be read in conjunction with
        our website Terms and Conditions. We reserve the right to change this
        Privacy Policy at any time.
      </p>

      <p>
        We respect the privacy of our users and every person who visits our
        sites www.spotiviral.com. Here, SPOTIVIRAL INC. (“company”, “we”, “us”,
        or “our”). We are committed to protecting your personal information and
        your right to privacy under the General Data Protection Regulation
        (GDPR). If you have any questions or concerns about our policy or our
        practices with regards to your personal information, please contact us
        at spotiviral@gmail.com.
      </p>

      <p>
        When you visit our website www.spotiviral.com (“Site”) and use our
        services, you trust us with your personal information. We take your
        privacy very seriously. In this privacy notice, we describe our privacy
        policy. We seek to explain to you in the clearest way possible what
        information we collect, how we use it, and what rights you have
        concerning it. We hope you take some time to read through it carefully,
        as it is important. If there are any terms in this privacy policy that
        you do not agree with, please discontinue the use of our site and our
        services.
      </p>

      <p>
        This privacy policy applies to all information collected through our
        website, and/or any related services, sales, marketing, or events (we
        refer to them collectively in this privacy policy as the “Site“).
      </p>

      <h2 className="text-xl font-bold">About Us</h2>

      <p>
        Spotiviral is a leading music promotion and sharing company for artists
        who are doing music on Spotify.
      </p>

      <p>
        We strive for the highest in quality and provide you access to thousands
        of music contents and other contents from artists and content providers
        globally.
      </p>

      <p>We are located in Sri Lanka.</p>

      <p>
        Please read this privacy policy carefully as it will help you make
        informed decisions about sharing your personal information with us.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        1. WHAT INFORMATION DO WE COLLECT?
      </h2>

      <p>
        <strong>The Personal Information you disclose to us</strong>
      </p>

      <p>
        We collect personal information that you voluntarily provide to us when
        expressing an interest in obtaining information about us or our services
        when participating in activities on the Site or otherwise contacting us.
      </p>

      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Site, the choices you make, and the
        products and features you use. The personal information we collect can
        include the following:
      </p>

      <ul>
        <li>
          Name and Contact Data. We collect your first and last name, email
          address, postal address, phone number, and other similar contact data.
        </li>
        <li>
          Credentials. We collect passwords, password hints, and similar
          security information used for authentication and account access.
        </li>
      </ul>

      <p>
        <strong>Information automatically collected</strong>
      </p>

      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Site. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser, and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Site and other technical information. If you access our site with
        your mobile device, we may automatically collect device information
        (such as your mobile device ID, model, and manufacturer), operating
        system, version information, and IP address. This information is
        primarily needed to maintain the security and operation of our Site, and
        for our internal analytics and reporting purposes.
      </p>

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies. You can find out more about this in our Cookie
        Policy.
      </p>

      <p>
        <strong>Information collected from other Sources</strong>
      </p>

      <p>
        We may obtain information about you from other sources, such as public
        databases, joint marketing partners, social media platforms (such as
        Facebook), as well as from other third parties.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        2. HOW DO WE USE YOUR INFORMATION?
      </h2>

      <p>
        We use your personal information for these purposes in reliance on our
        legitimate business interests (“Business Purposes”), to enter into or
        perform a contract with you (“Contractual”), with your consent
        (“Consent”), and/or for compliance with our legal obligations (“Legal
        Reasons”). We indicate the specific processing grounds we rely on next
        to each purpose listed below.
      </p>

      <p>We use the information we collect or receive:</p>

      <ul>
        <li>
          To send administrative information to you for related to your account,
          our business purposes, and/or for legal reasons. We may use your
          personal information to send you service and new feature information
          and/or information about changes to our terms, conditions, and
          policies.
        </li>
        <li>
          Deliver targeted advertising to you for our Business Purposes and/or
          with your Consent. We may use your information to develop and display
          content and advertising (and work with third parties who do so)
          tailored to your interests and/or location and to measure its
          effectiveness. [For more information, see our Cookie Policy.]
        </li>
        <li>
          Request Feedback for our Business Purposes and/or with your Consent.
          We may use your information to request feedback and to contact you
          about your use of our Site.
        </li>
        <li>
          To protect our Site for Business Purposes and/or Legal Reasons. We may
          use your information as part of our efforts to keep our Site safe and
          secure (for example, for fraud monitoring and prevention).
        </li>
        <li>
          To enable user-to-user communications with your consent. We may use
          your information to enable user-to-user communications with each
          user’s consent.
        </li>
        <li>
          To enforce our terms, conditions, and policies for our business
          purposes and as legally required.
        </li>
        <li>
          To respond to legal requests and prevent harm as legally required. If
          we receive a subpoena or other legal request, we may need to inspect
          the data we hold to determine how to respond.
        </li>
        <li>
          For other Business Purposes. We may use your information for other
          Business Purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns, and to
          evaluate and improve our Site, products, services, marketing, and your
          experience.
        </li>
      </ul>
      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </h2>

      <p>
        We only share and disclose your information in the following situations:
      </p>

      <ul>
        <li>
          <strong>Compliance with Laws.</strong> We may disclose your
          information where we are legally required to do so to comply with
          applicable law, governmental requests, a judicial proceeding, court
          order, or legal processes, such as in response to a court order or a
          subpoena (including in response to public authorities to meet national
          security or law enforcement requirements).
        </li>
        <li>
          <strong>Vital Interests and Legal Rights.</strong> We may disclose
          your information where we believe it is necessary to investigate,
          prevent, or take action regarding potential violations of our
          policies, suspected fraud, situations involving potential threats to
          the safety of any person and illegal activities, or as evidence in
          litigation in which we are involved.
        </li>
        <li>
          <strong>
            Vendors, Consultants, and Other Third-Party Service Providers.
          </strong>
          We may share your data with third-party vendors, service providers,
          contractors, or agents who perform services for us or on our behalf
          and require access to such information to do that work.
        </li>
        <li>
          <strong>Business Transfers.</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li>
          <strong>Third-Party Advertisers.</strong> We may use third-party
          advertising companies to serve ads when you visit the Site. These
          companies may use information about your visits to our Site and other
          websites that are contained in web cookies and other tracking
          technologies to provide advertisements about goods and services of
          interest to you.
        </li>
        <li>
          <strong>Affiliates.</strong> We may share your information with our
          affiliates, in which case we will require those affiliates to honor
          this privacy policy. Affiliates include our parent company and any
          subsidiaries, joint venture partners, or other companies that we
          control or that are under common control with us.
        </li>
        <li>
          <strong>Business Partners.</strong> We may share your information with
          our business partners to offer you certain products, services, or
          promotions.
        </li>
        <li>
          <strong>With your Consent.</strong> We may disclose your personal
          information for any other purpose with your consent.
        </li>
        <li>
          <strong>Other Users.</strong> When you share personal information (for
          example, by posting comments, contributions, or other content to the
          Site) or otherwise interact with public areas of the Site, such
          personal information may be viewed by all users and may be publicly
          distributed outside the Site in perpetuity.
        </li>
      </ul>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h2>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Policy.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </h2>

      <p>
        We will not transfer your personal information to an overseas recipient.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
      </h2>

      <p>
        The Site may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online
        services, or mobile applications. We cannot guarantee the safety and
        privacy of data you provide to any third parties. Any data collected by
        third parties is not covered by this privacy policy. We are not
        responsible for the content or privacy and security practices and
        policies of any third parties, including other websites, services, or
        applications that may be linked to or from the Site. You should review
        the policies of such third parties and contact them directly to respond
        to your questions.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        7. ANALYTICS AND ADVERTISING
      </h2>

      <h3>Google Analytics</h3>

      <p>
        This website uses Google Analytics, a web analytics service. It is
        operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA.
      </p>

      <p>
        Google Analytics uses so-called “cookies”. These are text files that are
        stored on your computer and that allow an analysis of the use of the
        website by you. The information generated by the cookie about your use
        of this website is usually transmitted to a Google server in the USA and
        stored there.
      </p>

      <p>
        Google Analytics cookies are stored based on Art. 6 (1) (f) DSGVO. The
        website operator has a legitimate interest in analyzing user behavior to
        optimize both its website and its advertising.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        8. HOW LONG DO WE KEEP YOUR INFORMATION?
      </h2>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this policy will
        require us to keep your personal information for longer than 90 days
        past the termination of your account.
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize it, or, if this
        is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>
      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        9. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </h2>

      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, please also remember that we cannot guarantee that the
        internet itself is 100% secure. Although we will do our best to protect
        your personal information, the transmission of personal information to
        and from our Site is at your own risk. You should only access the
        services within a secure environment.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        10. DO WE COLLECT INFORMATION FROM MINORS?
      </h2>

      <p>
        We do not knowingly solicit data from or market to children under 16
        years of age. By using the Site, you represent that you are at least 16
        or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Site. If we learn that personal
        information from users less than 16 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        have collected from children under age 16, please contact us at
        info@spotiviral.com.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        11. GDPR ENTITLEMENT
      </h2>

      <p>
        EU General Data Protection Regulation (GDPR) has provided the below
        rights to the EU residents:
      </p>

      <ul>
        <li>
          Right to information – The purposes for processing Personal
          Information and the rights of the individual.
        </li>
        <li>Right to access the Personal Information that is processed.</li>
        <li>Right to erasure (”Right to be forgotten”).</li>
        <li>Right to rectification.</li>
        <li>Right to restriction of the processing.</li>
        <li>Right to object (opt-out) to processing.</li>
      </ul>

      <p>
        EU residents can exercise these rights by raising a request directly at
        info@spotiviral.com.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        12. WHAT ARE YOUR PRIVACY RIGHTS?
      </h2>

      <p>
        <strong>Personal Information</strong>
      </p>

      <p>
        You may at any time review or change the information in your account or
        terminate your account by:
      </p>

      <ul>
        <li>Contacting us using the contact information provided below.</li>
      </ul>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use, and/or comply with legal requirements.
      </p>

      <p>
        <strong>Cookies and similar technologies:</strong>
      </p>

      <p>
        Most Web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove cookies and to
        reject cookies. If you choose to remove cookies or reject cookies, this
        could affect certain features or services of our Site.
      </p>
      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        13. CALIFORNIA PRIVACY RIGHTS
      </h2>

      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <h3 className="text-md font-bold mt-6 mb-4">
        ACCESS AND DATA PORTABILITY RIGHTS
      </h3>

      <p>
        You have the right to request that we disclose certain information to
        you about our collection and use of your Personal Information over the
        past 12 months. Once we receive and confirm your verifiable consumer
        request, we will disclose to you:
      </p>

      <ul>
        <li>The categories of Personal Information we collected about you.</li>
        <li>
          The categories of sources for the Personal Information we collected
          about you.
        </li>
        <li>
          Our business or commercial purpose for collecting or selling that
          Personal Information.
        </li>
        <li>
          The categories of third parties with whom we share that Personal
          Information.
        </li>
        <li>
          The specific pieces of Personal Information we collected about you
          (also called a data portability request).
        </li>
        <li>
          If we sold or disclosed your Personal Information for a business
          purpose, two separate lists disclosing (i) sales, identifying the
          Personal Information categories that each category of recipient
          purchased; and (ii) disclosures for a business purpose, identifying
          the Personal Information categories that each category of recipient
          obtained.
        </li>
      </ul>

      <h3 className="text-md font-bold mt-6 mb-4"> DELETION REQUEST RIGHTS</h3>

      <p>
        You have the right to request that website to delete your Personal
        Information, subject to certain exceptions. Once we receive and confirm
        your verifiable consumer request, we will delete (and direct our service
        providers to delete) your Personal Information from our records, unless
        an exception applies.
      </p>

      <p>
        We may deny your deletion request if retaining the information is
        necessary for us or our service provider(s) to:
      </p>

      <ul>
        <li>
          Complete the transaction for which we collected the Personal
          Information.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity (including fraudulent user generated
          content such as product reviews), or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>Exercise a right provided for by law.</li>
        <li>Comply with a legal obligation.</li>
        <li>Make other internal and lawful uses of that information.</li>
      </ul>

      <h3 className="text-md font-bold mt-6 mb-4">
        EXERCISING ACCESS, DATA PORTABILITY, AND DELETION RIGHTS
      </h3>

      <p>
        To exercise the access, data portability, and deletion rights described
        above, please submit a verifiable consumer request to us by sending us a
        message on our website. Only you, or a person registered with the
        California Secretary of State that you authorize to act on your behalf,
        may make a verifiable consumer request related to your Personal
        Information. You may also make a verifiable consumer request on behalf
        of your minor child.
      </p>

      <p>
        You may only make a verifiable consumer request for access or data
        portability twice within a 12-month period. The verifiable consumer
        request must:
      </p>

      <ul>
        <li>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected Personal Information or an
          authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </li>
      </ul>

      <p>
        We cannot respond to your request or provide you with Personal
        Information if we cannot verify your identity or authority to make the
        request and confirm the Personal Information relates to you.
      </p>

      <p>
        We will only use Personal Information provided in a verifiable consumer
        request to verify the requestor’s identity or authority to make the
        request.
      </p>

      <h3 className="text-md font-bold mt-6 mb-4">
        Response Timing and Format
      </h3>

      <p>
        We try to respond to a verifiable consumer request within forty-five
        (45) days of its receipt. If we require more time, we will inform you of
        the reason and extension period in writing.
      </p>

      <p>
        We will deliver our written response by mail or electronically, at your
        option.
      </p>

      <p>
        Any disclosures we provide will only cover the 12-month period preceding
        the verifiable consumer request’s receipt. The response we provide will
        also explain the reasons we cannot comply with a request, if applicable.
        For data portability requests, we will select a format to provide your
        Personal Information that is readily useable and should allow you to
        transmit the information from one entity to another entity without
        hindrance, specifically by electronic mail communication.
      </p>

      <p>
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>

      <h3 className="text-md font-bold mt-6 mb-4">OPT-OUT AND OPT-IN RIGHTS</h3>

      <p>
        If you are 16 years of age or older, you have the right to direct us to
        not sell your Personal Information at any time (the “right to opt-out”).
        We do not sell the Personal Information of consumers we actually know
        are less than 16 years of age, unless we receive affirmative
        authorization (the “right to opt-in”) from either the consumer who is
        between 13 and 16 years of age, or the parent or guardian of a consumer
        less than 13 years of age. Consumers who opt-in to Personal Information
        sales may opt-out of future sales at any time.
      </p>

      <p>
        To exercise the right to opt-out, you (or your authorized
        representative) may submit a request to us at email:
        info@spotiviral.com.
      </p>

      <p>
        We will only use Personal Information provided in an opt-out request to
        review and comply with the request.
      </p>

      <h3 className="text-md font-bold mt-6 mb-4">NON-DISCRIMINATION</h3>

      <p>
        We will not discriminate against you for exercising any of your CCPA
        rights. However, note that many features of our website, our products,
        or our services will not function without your Personal Information.
        Unless permitted by the CCPA, we will not:
      </p>

      <ul>
        <li>Deny you goods or services.</li>
        <li>
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>

      <p>
        However, we may offer you certain financial incentives permitted by the
        CCPA that can result in different prices, rates, or quality levels. Any
        CCPA-permitted financial incentive we offer will reasonably relate to
        your Personal Information’s value and contain written terms that
        describe the program’s material aspects. Participation in a financial
        incentive program requires your prior opt in consent, which you may
        revoke at any time.
      </p>

      <p>
        California’s “Shine the Light” law (Civil Code Section § 1798.83)
        permits users of our website that are California residents to request
        certain information regarding our disclosure of Personal Information to
        third parties for their direct marketing purposes. To make such a
        request, please send us an electronic message through our website or
        write us at our email address listed on our webpage.
      </p>
      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        14. DATA PROTECTION OFFICER
      </h2>

      <p>
        We have appointed a Data Protection Officer (“DPO”) who is responsible
        for overseeing questions about this privacy notice. If you have any
        questions about this privacy notice, including any requests to exercise
        your legal rights, please contact Data Protection Officer, at our e-mail
        spotiviral@gmail.com.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        15. DO WE MAKE UPDATES TO THIS POLICY?
      </h2>

      <p>
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy policy frequently
        to be informed of how we are protecting your information.
      </p>

      <h2 className="text-xl font-bold text-center mt-6 mb-4">
        16. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
      </h2>

      <p>
        If you have questions or comments about this policy, email us at
        spotiviral@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
