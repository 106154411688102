import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useState } from 'react';
import axios from 'axios';
const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const sendMail = async () => {
    try {
      const response = await axios.post(`${backendUrl}/contact`, {
        email: email,
        msg: message,
      });
      console.log(response);
      localStorage.removeItem("email");
      // Clear email and message fields after successful submission
      setEmail('');
      setMessage('');
      // Display toast notification for successful email sending
    toast.success("Email sent");

    } catch (error) {
      console.error('Error sending email:', error);
      // Display toast notification for error
    toast.error("Failed to send email");

    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    sendMail(); // Call sendMail function to send the email
  };

  return (
    <><ToastContainer />
    <div className="max-w-md mx-auto p-6 ">
      <h1 className="text-3xl font-bold mb-4 text-center mt-24">Send us an email</h1>
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-bold mb-2">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-6 py-3 rounded border border-gray-400 focus:outline-none focus:border-blue-500 text-lg"
            placeholder='Email'
            required />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-bold mb-2">Message</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            className="w-full px-6 py-3 rounded border border-gray-400 focus:outline-none focus:border-blue-500 text-lg"
            placeholder='Message'
            required
          ></textarea>
        </div>
        <button type="submit" className="text-center bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-24 rounded-lg focus:outline-none focus:shadow-outline mx-auto block">Send</button>
      </form>
      <p className="text-center text-xl mb-6 font-semibold">Need live assistance?</p>
      <a href="https://www.instagram.com/spotiviral/" target="_blank" rel="noopener noreferrer" className="block text-center bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded-full mx-auto">CONTACT ON INSTAGRAM</a>
    </div></>
  );
};

export default Contact;
